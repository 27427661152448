import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import {faMotorcycle, faUser} from '@fortawesome/pro-solid-svg-icons';

import './index.scss';
import ContactRow from '../ContactRow';
import Button from 'components/Button';

import {getDriverInfo} from 'utils/utils';

function DeliveryContactCard({orderData, onClickChangeDriver, onClickFindDriver}) {
	const {driverName, driverPhone} = getDriverInfo(orderData);
	const isDriverEmpty = (isEmpty(driverName) && isEmpty(driverPhone))
  || (driverName === 'Default' && driverPhone === 'Default');
	let title;
	let subtitle;
	switch (orderData.status) {
	case 'find_driver':
		title = 'Sedang mencari driver';
		subtitle = 'Mohon ditunggu...';
		break;
	case 'refind_driver':
		title = 'Sedang mencari driver baru';
		subtitle = 'Mohon ditunggu...';
		break;
	default:
		title = isDriverEmpty ? '-' : driverName;
		subtitle = isDriverEmpty
			? 'Sistem akan mencarikan driver maks. 10 menit setelah makanan telah selesai dibuat'
			: driverPhone + ' · ' + orderData.deliveryId;
	}
	return (
		<div className='DeliveryContactCard'>
			<ContactRow
				icon={faMotorcycle}
				description='Driver:'
				title={title}
				subtitle={subtitle}
				phoneNumber={driverPhone}
			>
				{/* {!orderData.isHistory && orderData.status !== 'paid' && (
					<React.Fragment>
						{isDriverEmpty && orderData.status !== 'find_driver' && (
							<div className='change-driver-section'>
								<div className='text-container'>
									<p>Belum dapat driver?</p>
									<p>PASTIKAN MAKANAN SUDAH SELESAI SEBELUM MEMANGGIL DRIVER</p>
								</div>
								<Button
									onClick={onClickFindDriver}
									size='sm'
									text='Cari Driver'
									color='red' />
							</div>
						)}
						{!isDriverEmpty && orderData.status !== 'refind_driver' && orderData.status !== 'find_driver' && (
							<div className='change-driver-section'>
								<div className='text-container'>
									<p>Driver tak kunjung datang?</p>
									<p>Kamu bisa mengganti driver di sini</p>
								</div>
								<Button
									onClick={onClickChangeDriver}
									size='sm'
									text='Ganti Driver'
									color='red' />
							</div>
						)}
					</React.Fragment>
				)} */}
			</ContactRow>
			<div className='border-seperator' />
			<ContactRow
				icon={faUser}
				description='Pemesan:'
				title={orderData.userName}
				phoneNumber={orderData.userPhone}
			/>
		</div>
	);
}

DeliveryContactCard.defaultProps = {
	orderData: {},
};

DeliveryContactCard.propTypes = {
	orderData: PropTypes.object,
};

export default DeliveryContactCard;