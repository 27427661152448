export const SET_USER_DATA = 'SET_USER_DATA';
export const SET_FIREBASE_TOKEN = 'SET_FIREBASE_TOKEN';
export const SET_FIREBASE_MESSAGING_TOKEN = 'SET_FIREBASE_MESSAGING_TOKEN';
export const SET_SELECTED_OUTLET = 'SET_SELECTED_OUTLET';
export const SET_SELECTED_OUTLET_ID = 'SET_SELECTED_OUTLET_ID';
export const SET_SELECTED_OUTLET_DATA = 'SET_SELECTED_OUTLET_DATA';
export const SET_AVAILABLE_OUTLETS = 'SET_AVAILABLE_OUTLETS';
export const SET_TOASTER_OPEN = 'SET_TOASTER_OPEN';
export const SET_TOASTER_DATA = 'SET_TOASTER_DATA';
export const SET_SCAN_MODAL_OPEN = 'SET_SCAN_MODAL_OPEN';
export const SET_NOTIFICATION_OFF_MODAL_OPEN = 'SET_NOTIFICATION_OFF_MODAL_OPEN';
export const SET_UNAVAILABLE_MENU_COUNT = 'SET_UNAVAILABLE_MENU_COUNT';
