import React from 'react';
import PropTypes from 'prop-types';

import {TextField} from '@material-ui/core';

import SwitchButton from 'components/SwitchButton';
import COLOR_PALLETES from 'assets/styles/_colors.scss';
import {DAYS_NAME} from 'utils/constants';

import './index.scss';
import {getIndonesianTimezoneLabel} from 'utils/utils';

function DayEntry({index, day, openHours, isOpen, onChange, timezone}) {
	const openHoursSplitted = openHours.replace(' ', '').split('-');

	const onChangeValue = (type, value) => {
		let data = {day, openHours, isOpen};
		switch (type) {
		case 'open':
			data.openHours = value + '-' + openHoursSplitted[1];
			break;
		case 'close':
			data.openHours = openHoursSplitted[0] + '-' + value;
			break;
		case 'isOpen':
			data.isOpen = value;
			break;
		default:
		}
		onChange(index, data);
	};

	return (
		<div className='DayEntry'>
			<div className='top-row row'>
				<div className='day-title'>{DAYS_NAME[day]}</div>
				<SwitchButton
					checked={isOpen}
					onClick={() => onChangeValue('isOpen', !isOpen)}
					style={{marginTop: 4}}
				/>
			</div>
			<div className='switch-description'>
				Status: <span
					className='bold-text'
					style={{color: isOpen ? COLOR_PALLETES.matcha : COLOR_PALLETES.sauceLight}}>
					{isOpen ? 'Buka Sesuai Jadwal' : 'Tutup sepanjang hari'}
				</span>
			</div>
			<div className='time-container row'>
				<TextField
					label="Jam buka"
					type="time"
					value={openHoursSplitted[0]}
					InputLabelProps={{shrink: true}}
					InputProps={{}}
					onChange={({target}) => onChangeValue('open', target.value)}
				/>
				<div className='time-seperator'>-</div>
				<TextField
					label="Jam tutup"
					type="time"
					value={openHoursSplitted[1]}
					InputLabelProps={{shrink: true}}
					onChange={({target}) => onChangeValue('close', target.value)}
				/>
				<div className='time-seperator'>{getIndonesianTimezoneLabel(timezone)}</div>
			</div>
		</div>
	);
}

DayEntry.defaultProps = {
	index: 0,
	day: 'mon',
	openHours: '00:00-23:59',
	timezone: 'Asia/Jakarta',
	isOpen: false,
	onChange: () => null,
};

DayEntry.propTypes = {
	index: PropTypes.number,
	day: PropTypes.string,
	openHours: PropTypes.string,
	timezone: PropTypes.string,
	isOpen: PropTypes.bool,
	onChange: PropTypes.func,
};

export default DayEntry;