import React from 'react';
import {useSelector} from 'react-redux';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import './index.scss';

function MenuTabBar({selectedTab, onChangeTab}) {
	const isUnavailableMode = window.location.pathname.includes('turnedoffmenus');

	const selectedOutletData = useSelector(
		state => state.outlet.selectedOutletData,
	);

	const tabsConfig = [
		{label: 'Menu Utama', value: 'mainMenu'},
		{label: 'Menu Paket', value: 'packageMenu'},
		{label: 'Ala Carte', value: 'alaCarte'},
		{label: 'Extra/Topping', value: 'subMenu'},
	];
	const tabs = [];
	tabsConfig.forEach((e, i) => {
		selectedOutletData.isDelivery &&
			tabs.push(
				<Tab
					key={i + 'delivery'}
					label={`${e.label} (Delivery)`}
					value={`${e.value}-delivery`}
				/>,
			);
		selectedOutletData.isDinein &&
			tabs.push(
				<Tab
					key={i + 'dinein'}
					label={`${e.label} (Dine In)`}
					value={`${e.value}-dinein`}
				/>,
			);
	});

	return selectedTab ? (
		<div className="MenuTabBar">
			<Tabs
				value={selectedTab}
				onChange={(e, newValue) => onChangeTab(newValue)}
				variant="scrollable"
				className="order-tab"
				TabIndicatorProps={{children: <div />}}>
				{isUnavailableMode && selectedOutletData.isDelivery && (
					<Tab
						label="Semua (Delivery)"
						value="all-delivery" />
				)}
				{isUnavailableMode && selectedOutletData.isDinein && (
					<Tab
						label="Semua (Dinein)"
						value="all-dinein" />
				)}
				{tabs}
			</Tabs>
		</div>
	) : null;
}

export default MenuTabBar;
