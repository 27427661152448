import firebase from 'firebase/app';
import moment from 'moment';
import Axios from 'axios';
import get from 'lodash/get';

// Global utils
import {ORDER_FILTER, ORDER_STATUS, TOASTER_STATUS} from 'utils/constants';
import logger from 'utils/logger';

// Redux
import store from 'store';
import {setToasterData, setToasterOpen} from 'store/actions';

export const filterData = (orderData, setOrderDisplayedData) => {
	let data = {};

	data[ORDER_FILTER.PENDING_ORDER] = orderData.filter(order =>
		[ORDER_STATUS.PAID].includes(order.status),
	).sort((a, b) => a.paidAt - b.paidAt);

	data[ORDER_FILTER.DELIVERY_PROCESS] = orderData.filter(order =>
		[
			ORDER_STATUS.PROCESSED,
			ORDER_STATUS.FIND_DRIVER,
			ORDER_STATUS.DRIVER_FOUND,
			ORDER_STATUS.REFIND_DRIVER,
			ORDER_STATUS.PICKUP_READY,
		].includes(order.status) && order.type === 'delivery',
	).sort((a, b) => a.paidAt - b.paidAt);

	data[ORDER_FILTER.TAKEAWAY_PROCESS] = orderData.filter(order =>
		[
			ORDER_STATUS.PROCESSED,
			ORDER_STATUS.FIND_DRIVER,
			ORDER_STATUS.DRIVER_FOUND,
			ORDER_STATUS.REFIND_DRIVER,
			ORDER_STATUS.PICKUP_READY,
		].includes(order.status) && order.type === 'takeaway',
	).sort((a, b) => a.paidAt - b.paidAt);

	return data;
};

export const checkIfRobotIsOnline = latestTime => {
	if (latestTime) {
		return moment().isSameOrBefore(moment(latestTime).add(12, 'minute'));
	}
	return false;
};

export const checkAndShowProcessedToast = data => {
	const notYetToastedFound = data.find(e => e.require_manual_process === false && !e.success_toast_shown);
	if (notYetToastedFound) {
		store.dispatch(setToasterData({message: 'Pesanan berhasil diproses. Cek pesanan di kolom delivery/takeaway', status: TOASTER_STATUS.SUCCESS}));
		store.dispatch(setToasterOpen(true));
		firebase.database().ref('active_orders/' + notYetToastedFound.firebaseKey).update({success_toast_shown: true});
	}
};

export const onTriggerAutomaticProcess = async order => {
	store.dispatch(setToasterData({message: 'Pesanan sedang diproses otomatis dan akan pindah kolom ketika berhasil', status: TOASTER_STATUS.SUCCESS}));
	store.dispatch(setToasterOpen(true));
	if (order) {
		try {
			const {data} = await Axios.post(`${process.env.REACT_APP_HANGRY_SERVICE_API}/intools/gql`, {
				query: `mutation resubmitOrderToPOS($orderId:uuid!,$outletId:uuid!) {
          resubmitOrderToPOS(orderId:$orderId,outletId:$outletId){
            success
            message
          }
        }`,
				variables: {
					orderId: order.id,
					outletId: order.outletId,
				},
			}, {
				headers: {
					Authorization: localStorage.getItem('loginToken'),
				},
			});

			if (get(data, 'errors[0].extensions.code') === 'UNAUTHENTICATED') return window.location.reload();
			if (data.errors) throw new Error(JSON.stringify(data.errors));

			if (data.data.resubmitOrderToPOS.success === 'false') throw new Error(data.data.resubmitOrderToPOS.message);
		} catch (error) {
			logger.notify(error);
			// show snackbar
			store.dispatch(setToasterData({message: 'Gagal melakukan proses otomatis', status: TOASTER_STATUS.FAILED}));
			store.dispatch(setToasterOpen(true));
			console.log(error);
		}
	}
};