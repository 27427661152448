import firebase from 'firebase/app';
import Axios from 'axios';
import {get, isEmpty} from 'lodash';
import moment from 'moment';

// Redux
import store from 'store';
import {setToasterData, setToasterOpen} from 'store/actions';

// Global utils
import {TOASTER_STATUS} from 'utils/constants';
import logger from 'utils/logger';

export const processOrder = async (firebaseKey, currentStatus) => {
	const payload = currentStatus === 'paid' ? {
		status: 'processed',
		processedAt: moment().format(),
	} : {};
	payload.require_manual_process = false;
	payload.success_toast_shown = true;
	await firebase.database()
		.ref('active_orders/' + firebaseKey)
		.update(payload, error => {
			if (error) {
				// Toaster
				store.dispatch(setToasterData({message: 'Pesanan gagal diproses manual', status: TOASTER_STATUS.ERROR}));
			} else {
				// Toaster
				store.dispatch(setToasterData({message: 'Pesanan berhasil diproses manual', status: TOASTER_STATUS.SUCCESS}));
			}
			store.dispatch(setToasterOpen(true));
		});
};

export const fetchFireaseDatabase = async (refPath, shortIdToFetch, isHistory) => {
	return firebase.database().ref(refPath)
		.orderByChild('shortId')
		.equalTo(shortIdToFetch)
		.once('value')
		.then(function (snapshot) {
			const data = snapshot.val();
			if (!isEmpty(data)) {
				const firebaseKey = Object.keys(data)[0];
				return Object.assign(data[firebaseKey], {firebaseKey, isHistory});
			}
		});
};

export const fetchOrder = async shortIdToFetch => {
	let result;
	result = await fetchFireaseDatabase('active_orders', shortIdToFetch, false);
	if (isEmpty(result)) {
		const selectedOutletId = store.getState().outlet.selectedOutletId;
		result = await fetchFireaseDatabase(`history_orders/${selectedOutletId}`, shortIdToFetch, true);
	}
	return result;
};

export const setOrderStatus = async (orderId, outletId, status) => {
	try {
		const {data} = await Axios.post(`${process.env.REACT_APP_HANGRY_SERVICE_API}/intools/gql`, {
			query: `mutation UpdateOrderStatus($orderId: uuid!, $outletId: uuid!, $status: String!, $notes: String!) {
        updateOrderStatus(orderId: $orderId, outletId: $outletId, status: $status, notes: $notes) {
          success,
          message
        }
      }`,
			variables: {
				orderId,
				outletId,
				status,
				notes: '',
			},
		}, {
			headers: {
				Authorization: localStorage.getItem('loginToken'),
			},
		});

		if (get(data, 'errors[0].extensions.code') === 'UNAUTHENTICATED') return window.location.reload();
		if (data.errors) throw new Error(JSON.stringify(data.errors));

		if (data.data.updateOrderStatus.success === 'false') throw new Error(data.data.updateOrderStatus.message);
	} catch (error) {
		logger.notify(error);
		throw error;
	}
};

export const togglePickupReady = async (...props) => {
	try {
		await setOrderStatus(...props, 'pickup_ready');
		store.dispatch(setToasterData({message: 'Pesanan siap diambil dan customer sudah diberi tahu', status: TOASTER_STATUS.SUCCESS}));
		store.dispatch(setToasterOpen(true));
	} catch (error) {
		store.dispatch(setToasterData({message: 'Gagal mengubah status pesanan', status: TOASTER_STATUS.FAILED}));
		store.dispatch(setToasterOpen(true));
	}
};

export const findDriver = async (orderId, outletId) => {
	try {
		const {data} = await Axios.post(`${process.env.REACT_APP_HANGRY_SERVICE_API}/intools/gql`, {
			query: `mutation findDriver($orderId:uuid!,$outletId:uuid!) {
        findDriver(orderId:$orderId,outletId:$outletId){
          success
          message
        }
      }`,
			variables: {
				orderId,
				outletId,
			},
		}, {
			headers: {
				Authorization: localStorage.getItem('loginToken'),
			},
		});

		if (get(data, 'errors[0].extensions.code') === 'UNAUTHENTICATED') return window.location.reload();
		if (data.errors) throw new Error(JSON.stringify(data.errors));

		if (data.data.findDriver.success === 'false') throw new Error(data.data.findDriver.message);
		store.dispatch(setToasterData({message: 'Sedang mencari driver, mohon ditunggu', status: TOASTER_STATUS.SUCCESS}));
		store.dispatch(setToasterOpen(true));
	} catch (error) {
		store.dispatch(setToasterData({message: 'Gagal melakukan pencarian driver', status: TOASTER_STATUS.FAILED}));
		store.dispatch(setToasterOpen(true));
	}
};

export const replaceDriver = async (orderId, outletId) => {
	try {
		const {data} = await Axios.post(`${process.env.REACT_APP_HANGRY_SERVICE_API}/intools/gql`, {
			query: `mutation replaceOrderDriver($orderId:uuid!,$outletId:uuid!) {
        replaceOrderDriver(orderId:$orderId,outletId:$outletId){
          success
          message
        }
      }`,
			variables: {
				orderId,
				outletId,
			},
		}, {
			headers: {
				Authorization: localStorage.getItem('loginToken'),
			},
		});

		if (get(data, 'errors[0].extensions.code') === 'UNAUTHENTICATED') return window.location.reload();
		if (data.errors) throw new Error(JSON.stringify(data.errors));

		if (data.data.replaceOrderDriver.success === 'false') throw new Error(data.data.replaceOrderDriver.message);

		// show snackbar
		store.dispatch(setToasterData({message: 'Berhasil mencari driver baru', status: TOASTER_STATUS.SUCCESS}));
		store.dispatch(setToasterOpen(true));
	} catch (error) {
		logger.notify(error);
		// show snackbar
		store.dispatch(setToasterData({message: 'Gagal mencari driver baru', status: TOASTER_STATUS.FAILED}));
		store.dispatch(setToasterOpen(true));
		console.log(error);
	}
};