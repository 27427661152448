import React from 'react';
import get from 'lodash/get';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import {ORDER_FILTER} from 'utils/constants';

import './index.scss';

function OrderTabBar({selectedOrderTab, data, onChangeTab}) {
	return (
		<div className='OrderTabBar'>
			<Tabs
				value={selectedOrderTab}
				onChange={(e, newValue) => onChangeTab(newValue)}
				variant='fullWidth'
				className='order-tab'
			>
				<Tab
					label='Pending'
					value={ORDER_FILTER.PENDING_ORDER}
					icon={
						get(data, `${ORDER_FILTER.PENDING_ORDER}.length`, 0)
							? <div className='count-indicator row'>{data[ORDER_FILTER.PENDING_ORDER].length}</div>
							: null
					}
				/>
				<Tab
					label='Delivery'
					value={ORDER_FILTER.DELIVERY_PROCESS}
					icon={
						get(data, `${ORDER_FILTER.DELIVERY_PROCESS}.length`, 0)
							? <div className='count-indicator row'>{data[ORDER_FILTER.DELIVERY_PROCESS].length}</div>
							: null
					}
				/>
				<Tab
					label='Takeaway'
					value={ORDER_FILTER.TAKEAWAY_PROCESS}
					icon={
						get(data, `${ORDER_FILTER.TAKEAWAY_PROCESS}.length`, 0)
							? <div className='count-indicator row'>{data[ORDER_FILTER.TAKEAWAY_PROCESS].length}</div>
							: null
					}
				/>
			</Tabs>
		</div >
	);
}

export default OrderTabBar;