import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';

function BrandList({title, src, style, onClick}) {
  return (
    <div className='BrandList' style={style}>
      <div className="image-aspect-ratio touchable-highlight" onClick={onClick}>
        <div className="image-container">
          <img src={src} alt={title} className='image' />
        </div>
      </div>
    </div>
  )
}



BrandList.defaultProps = {
  title: '',
  src: '',
  onClick: () => {},
}
BrandList.propTypes = {
  title: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  onClick: PropTypes.func,
}

export default BrandList;