import React, {useState, useEffect, useRef} from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {get, isEmpty} from 'lodash';

import {Container} from '@material-ui/core';

import {faChevronLeft} from '@fortawesome/pro-solid-svg-icons';

import FullScreenLoading from 'components/FullScreenLoading';
import Header from 'components/Header';
import {
	checkImpactedMenus,
	fetchToggleMenu,
	fetchToggleSubmenu,
	fetchAllMenus,
	fetchUnavailableMenus,
	checkLinkedMenus,
} from 'utils/request/menu';

import './index.scss';
import ToggleMenuModal from './components/ToggleMenuModal';
import PackageMenuOpenerModal from './components/PackageMenuOpenerModal';
import MenuTabBar from './components/TabBar';
import MenuItem from './components/MenuItem';
import EmptyMenus from './components/EmptyMenus';
import SearchInput from './components/SearchInput';

function MenuList() {
	const packageMenuModalRef = useRef();
	const history = useHistory();
	const isUnavailableMode = history.location.pathname.includes(
		'turnedoffmenus',
	);

	const selectedOutletData = useSelector(
		state => state.outlet.selectedOutletData,
	);

	const brandName = get(history, 'location.state.brandName', '');

	const [selectedTab, setSelectedTab] = useState();

	const [menus, setMenus] = useState(null);
	const [searchValue, setSearchValue] = useState('');

	const [isCheckingImpactedMenus, setIsCheckingImpactedMenus] = useState(false);
	const [toggleMenuLoading, setToggleMenuLoading] = useState(false);
	const [ToggleMenuDialogOpen, setToggleMenuDialogOpen] = useState(false);
	const [activeDialogData, setActiveDialogData] = useState({
		data: {},
		impactedMenus: [],
	});

	const [packageMenuDialogOpen, setPackageMenuDialogOpen] = useState(false);
	const [activePackageMenuData, setActivePackageMenuData] = useState({});

	const onClickToggle = async data => {
		let impactedMenus = [];
		if (data.isPackage && !data.isAvailable) {
			setActivePackageMenuData(data);
			setPackageMenuDialogOpen(true);
		} else {
			setToggleMenuDialogOpen(true);
			setIsCheckingImpactedMenus(true);
			impactedMenus = data.isAvailable
				? await checkImpactedMenus(data.id)
				: await checkLinkedMenus(data.id);
			setIsCheckingImpactedMenus(false);
		}

		setActiveDialogData({data, impactedMenus});
	};

	const changeToggle = async () => {
		setToggleMenuDialogOpen(false);
		setToggleMenuLoading(true);

		const {data} = activeDialogData;

		const isSuccess = data.isSubmenu
			? await fetchToggleSubmenu(data.id, data.brandId, !data.isAvailable)
			: await fetchToggleMenu(data.id, data.brandId, !data.isAvailable);

		if (isSuccess) {
			await fetchMenus();
			if (packageMenuDialogOpen) packageMenuModalRef.current.fetchPackageData();
			if (activePackageMenuData.id === data.id) setPackageMenuDialogOpen(false);
		}
		setToggleMenuLoading(false);
	};

	const fetchMenus = async () => {
		const type =
			selectedOutletData.isDelivery && selectedOutletData.isDinein
				? 'all'
				: selectedOutletData.isDinein
					? 'dinein'
					: 'delivery';
		const result = isUnavailableMode
			? await fetchUnavailableMenus()
			: await fetchAllMenus(type);
		setMenus(result);
	};

	useEffect(() => {
		(async () => {
			if (selectedOutletData.id) {
				await fetchMenus();
				const initialType = selectedOutletData.isDelivery
					? 'delivery'
					: 'dinein';
				setSelectedTab(
					isUnavailableMode ? `all-${initialType}` : `mainMenu-${initialType}`,
				);
			}
		})();
		// eslint-disable-next-line
	}, [selectedOutletData.id]);

	const renderItemEntry = () => {
		const renderMenus = (menus = []) =>
			menus.map(data => (
				<MenuItem
					key={data.id}
					menuName={data.label}
					price={data.price}
					isAvailable={data.isAvailable}
					isLoading={activeDialogData.data.id === data.id && toggleMenuLoading}
					onClickToggle={() => onClickToggle(data)}
				/>
			));
		const menuList =
			selectedTab === 'all-delivery'
				? Object.values(menus)
					.flat()
					.filter(e => e.type === 'delivery')
				: selectedTab === 'all-dinein'
					? Object.values(menus)
						.flat()
						.filter(e => e.type === 'dinein')
					: menus[selectedTab] || [];

		if (!isEmpty(searchValue)) {
			const searchedMenus = menuList.filter(e =>
				e.label.toLowerCase().includes(searchValue.toLowerCase()),
			);
			return searchedMenus.length ? (
				renderMenus(searchedMenus)
			) : (
				<EmptyMenus isSearchMode={true} />
			);
		}
		return menuList.length ? (
			renderMenus(menuList)
		) : (
			<EmptyMenus isUnavailableMode={isUnavailableMode} />
		);
	};

	return (
		<div className="MenuList">
			<Header
				title={brandName}
				faLeftIcon={faChevronLeft}
				onClickLeftIcon={() => history.goBack()}
				renderBottomBorder
			/>
			<SearchInput
				value={searchValue}
				onChange={e => setSearchValue(e)} />
			<MenuTabBar
				selectedTab={selectedTab}
				onChangeTab={value => setSelectedTab(value)}
			/>
			<Container
				maxWidth="md"
				className="container">
				{menus !== null ? (
					<div className="list-container">{renderItemEntry()}</div>
				) : (
					<FullScreenLoading />
				)}
			</Container>

			{/* Modal */}
			<ToggleMenuModal
				open={ToggleMenuDialogOpen}
				loading={isCheckingImpactedMenus}
				activeLabel={activeDialogData.data.label}
				activeImpacted={activeDialogData.impactedMenus}
				isCurrentlyAvailable={activeDialogData.data.isAvailable}
				changeToggle={changeToggle}
				closeDialog={() => setToggleMenuDialogOpen(false)}
			/>

			<PackageMenuOpenerModal
				ref={packageMenuModalRef}
				open={packageMenuDialogOpen}
				activePackageData={activePackageMenuData}
				toggleMenuId={activeDialogData.data.id}
				toggleMenuLoading={toggleMenuLoading}
				onToggleMenu={onClickToggle}
				closeDialog={() => setPackageMenuDialogOpen(false)}
			/>
		</div>
	);
}

export default MenuList;
