import React from 'react';
import {useHistory} from 'react-router-dom';
import PulseLoader from 'react-spinners/PulseLoader';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes} from '@fortawesome/pro-solid-svg-icons';

import COLOR_PALLATES from 'assets/styles/_colors.scss';

import './style.scss';

function ScanOverlay({isLoading}) {
  const history = useHistory();

  return (
    <div className="ScanOverlay">
      <div className='scanBox'>
        <div className='animating-border' />
        {isLoading &&
          <div className='loading-container row'>
            <div className='loading-card row'>
              <PulseLoader color={COLOR_PALLATES.sauce} size={8} />
            </div>
          </div>
        }
      </div>
      <div className='scan-title'>Scan QR</div>
      <div className='scan-description'>Scan kode khusus takeaway yang dimiliki customer untuk menyelesaikan pesanan</div>
      <div className='icon-container row touchable-opacity' onClick={() => history.goBack()}>
        <FontAwesomeIcon icon={faTimes} className='icon' />
      </div>
    </div>
  )
}

export default ScanOverlay;