import { useState } from "react";

export const numberToRupiah = (number = 0) => {
    return 'Rp' + new Intl.NumberFormat('id-ID', {
        style: 'decimal',
        currency: 'IDR',
        maximumFractionDigits: 2,
        minimumFractionDigits: 0,
    }).format(number);
}

export const getSubmenuText = submenuData => {
    switch (submenuData.category) {
        case 'Sugar Level':
            return `${upperCaseFirstLetter(submenuData.label)} Sugar`;
        case 'Level Pedas':
            return `Level Pedas ${submenuData.label}`;
        case 'Ice Level':
            return `${upperCaseFirstLetter(submenuData.label)} Ice`;
        case 'Tambahan':
            return `Extra ${submenuData.label}`;
        default:
            return submenuData.label
    }
};

export const upperCaseFirstLetter = word => {
    return word && word.replace(/^\w/, c => c.toUpperCase())
};

export const isMobile = () => {
    const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i
    ];

    return toMatch.some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem);
    });
}

export const useDisclosure = () => {
    const [isOpen, isOpenSet] = useState();    
    const onChange = (value) => isOpenSet(value);
    return {isOpen, onOpen: () => onChange(true), onClose:() => onChange(false)}
}