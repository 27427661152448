import {faChevronRight, faInfoCircle} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useHistory} from 'react-router-dom';
import React from 'react';
import PropTypes from 'prop-types';

import COLOR_PALLETES from 'assets/styles/_colors.scss';
import {getIndonesianTimezoneLabel} from 'utils/utils';
import {DAYS_NAME} from 'utils/constants';

import './index.scss';

function OutletScheduleDisplay({schedules, timezone}) {
	const history = useHistory();

	return (
		<div className='OutletScheduleDisplay'>
			<div className='schedule-header row'>
				<div className='schedule-title'>Jadwal Reguler</div>
				<div
					className='reschedule-button row touchable-opacity'
					onClick={() => history.push('/scheduler')}>
					<div className='reschedule-text'>Atur Jadwal</div>
					<div className='icon-container row'><FontAwesomeIcon
						className='icon'
						icon={faChevronRight}
						color={COLOR_PALLETES.sauce} /></div>
				</div>
			</div>
			<div className='seperator' />

			<div className='schedules-container'>
				{schedules.map(({days, isOpen, openHours}, i) => {
					const dayDisplay = days.length > 1
						? `${DAYS_NAME[days[0]]} - ${DAYS_NAME[days[days.length - 1]]}`
						: DAYS_NAME[days[0]];
					return (
						<div
							className='schedule-entry row'
							key={i}>
							<div className='schedule-day-name'>{dayDisplay}</div>
							<div className='schedule-day-value'>{isOpen ? openHours.replace('-', ' - ') + ` (${getIndonesianTimezoneLabel(timezone)})` : 'Tutup'}</div>
						</div>
					);
				})}
			</div>

			<div className='info-container row'>
				<div className='icon-container row'><FontAwesomeIcon
					className='icon'
					icon={faInfoCircle}
					color={COLOR_PALLETES.blackLight} /></div>
				<div className='info-text'>
					Tekan atur jadwal untuk mengatur jadwal buka dan tutup outlet jika diperlukan
				</div>
			</div>
		</div>
	);
}

OutletScheduleDisplay.defaultProps = {
	schedules: [],
	timezone: 'Asia/Jakarta',
};

OutletScheduleDisplay.propTypes = {
	schedules: PropTypes.array,
	timezone: PropTypes.string,
};

export default OutletScheduleDisplay;