import React, {useState} from 'react';
import {useHistory} from 'react-router';
import PulseLoader from 'react-spinners/PulseLoader';

// Global
import {scanPickUpQR} from 'utils/request/scanQR';
import COLOR_PALLATES from 'assets/styles/_colors.scss';
import {useEffect} from 'react';


import './index.scss';
import {useDispatch} from 'react-redux';
import {setScanModalOpen} from 'store/actions';

let qrCode = '';
let qrWaitTimeout;

function QRDeviceListener() {

  const [loadingOverlay, setLoadingOverlay] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();

  const isInOrderDetail = /order\/\w+/.test(history.location.pathname);

  const pickUpOrder = async (QRPayload) => {
    const shortId = await scanPickUpQR(QRPayload);
    if (shortId) {
      dispatch(setScanModalOpen(false))
      if (isInOrderDetail) {
        history.replace('/order/' + shortId);
        window.location.reload();
      } else {
        history.push('/order/' + shortId);
      }
    }
    setIsSubmitting(false);
    setLoadingOverlay(false);
  }

  const submitQRPayload = (newCode) => {
    !isSubmitting && pickUpOrder(newCode);
  }

  useEffect(() => {
    const qrCodeListener = (e) => {
      const submitQR = () => {
        setIsSubmitting(true);
        submitQRPayload(qrCode);
        qrCode = '';
      }

      if (!['input', 'textarea'].includes(e.target.tagName.toLowerCase())) {
        clearTimeout(qrWaitTimeout);
        qrWaitTimeout = setTimeout(() => submitQR(), 2000); // submit QR if 2s of empty input, as some qr scanner didn't input enter
        setLoadingOverlay(true);
        if (e.code.toLowerCase() === 'enter') {
          clearTimeout(qrWaitTimeout);
          submitQR();
        } else {
          qrCode += e.key
        }
      }
    };

    document.body.addEventListener('keypress', qrCodeListener, false);

    return () => document.body.removeEventListener('keypress', qrCodeListener);
  }, [isSubmitting, loadingOverlay]); //eslint-disable-line

  return loadingOverlay ? (
    <div className="QRDeviceListener">
      <div className='loading-card row'>
        <PulseLoader color={COLOR_PALLATES.sauce} size={8} />
      </div>
    </div>
  ) : null
}

export default QRDeviceListener;