import React from 'react';
import PropTypes from 'prop-types';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheckCircle, faTimes} from '@fortawesome/pro-solid-svg-icons';

import COLOR_PALLETES from 'assets/styles/_colors.scss';

import './index.scss';


function Cutlery({useCutlery}) {

	return (
		<div className='Cutlery'>
			<div className='text-row'>
				<div className='title'>Alat Makan</div>
				<div className='description'>
					{useCutlery
						? 'Pelanggan memesan alat makan'
						: 'Pelanggan tidak memesan alat makan'
					}
				</div>
			</div>
			<div
				className='icon-container row'
				style={{
					backgroundColor: useCutlery
						? COLOR_PALLETES.matchaLightest
						: COLOR_PALLETES.sauceLightest,
				}}
			>
				<FontAwesomeIcon
					icon={useCutlery ? faCheckCircle : faTimes}
					className='icon'
					color={useCutlery ? COLOR_PALLETES.matcha : COLOR_PALLETES.sauceLight}
				/>
			</div>
		</div>
	);
}

Cutlery.defaultProps = {
	useCutlery: true,
};

Cutlery.propTypes = {
	useCutlery: PropTypes.bool,
};


export default Cutlery;