import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import PulseLoader from 'react-spinners/PulseLoader';

import {Container} from '@material-ui/core';

import {faChevronLeft} from '@fortawesome/pro-solid-svg-icons';

// Global
import Button from 'components/Button';
import Header from 'components/Header';
import COLOR_PALLETES from 'assets/styles/_colors.scss';
import {fetchSelectedOutletData} from 'utils/request/outlet';
import {DAYS_NAME} from 'utils/constants';

// Local
import './index.scss';
import DayEntry from './components/DayEntry';
import {isEmpty} from 'lodash';
import {useSelector} from 'react-redux';
import {updateOutletSchedule} from './utils/fetch';
import ActionModal from 'components/ActionModal';

function Scheduler() {
	const history = useHistory();
	const [openHours, setOpenHours] = useState([]);
	const [isSomeEmpty, setIsSomeEmpty] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const selectedOutletData = useSelector(state => state.outlet.selectedOutletData);

	const onChangeOpenHours = (index, data) => {
		const temp = [...openHours];
		temp[index] = data;
		setOpenHours(temp);
		setIsSomeEmpty(temp.some(entry => entry.openHours.length !== 11));
	};

	const submitSchedule = async () => {
		setIsLoading(true);
		const isSuccess = await updateOutletSchedule(openHours);
		if (isSuccess) {
			history.goBack();
		}
		setIsModalOpen(false);
		setIsLoading(false);
	};

	const fetchOpenHours = async () => {
		const result = await fetchSelectedOutletData();
		if (result) {
			setOpenHours(result.openHours.days.filter(entry => DAYS_NAME[entry.day]));
		}
	};

	useEffect(() => {
		if (selectedOutletData.id) fetchOpenHours();
	}, [selectedOutletData.id]);

	return (
		<div className='Scheduler'>
			<Header
				title='Atur Jadwal Reguler'
				renderBottomBorder
				faLeftIcon={faChevronLeft}
				onClickLeftIcon={() => history.goBack()} />
			<Container
				maxWidth='md'
				className='day-list'>
				<div className='top-container'>
					{isEmpty(openHours) ? (
						<div className='loading-screen'><PulseLoader
							color={COLOR_PALLETES.sauce}
							size={16}
							margin={8} /></div>
					) :
						openHours.map((entry, i) => (
							<DayEntry
								key={i}
								index={i}
								day={entry.day}
								openHours={entry.openHours}
								isOpen={entry.isOpen}
								onChange={onChangeOpenHours}
								timezone={selectedOutletData.timezone}
							/>
						),
						)
					}
				</div>
				<div className='submit-button-container'>
					<Button
						type='primary'
						size='lg'
						color='red'
						text='Simpan'
						disabled={isSomeEmpty}
						loading={false}
						className='submit-button'
						onClick={() => setIsModalOpen(true)}
						async />
				</div>

				<ActionModal
					open={isModalOpen}
					loading={isLoading}
					title='Simpan jadwal'
					description='Pastikan perubahan jadwal reguler telah sesuai sebelum menyimpan'
					leftButtonText='Batal'
					rightButtonText='Simpan'
					onBackdropClick={() => setIsModalOpen(false)}
					onClickLeft={() => setIsModalOpen(false)}
					onClickRight={submitSchedule}
				/>
			</Container>
		</div >
	);
}

export default Scheduler;