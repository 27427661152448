import React, {useState} from 'react';
import PropTypes from 'prop-types';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExclamationCircle} from '@fortawesome/pro-solid-svg-icons';

import Button from 'components/Button';
import ActionModal from 'components/ActionModal';

import './index.scss';

function BottomButtons({orderData, onProcessOrder, onClickCancel, loading}) {
	const [manualModalOpen, setManualModalOpen] = useState(false);
	const isPendingOrder = orderData.require_manual_process !== false;

	return (
		<>
			{isPendingOrder && (
				<div className="PendingAlert row">
					<div className="icon-container">
						<FontAwesomeIcon
							icon={faExclamationCircle}
							className="alert-icon"
						/>
					</div>
					Pastikan kamu telah memasukkan pesanan ke POS sebelum menekan tombol &apos;Proses Manual&apos;
				</div>
			)}
			<div className='BottomButtons row'>
				<Button
					type='secondary'
					size='lg'
					color='red'
					text='Batalkan'
					disabled={orderData.isHistory}
					style={{flex: 1}}
					onClick={onClickCancel}
				/>
				{isPendingOrder && (
					<>
						<Button
							type='primary'
							size='lg'
							color='red'
							text="Proses Manual"
							loading={loading}
							style={{flex: 2}}
							onClick={() => setManualModalOpen(true)}
						/>
						<ActionModal
							open={manualModalOpen}
							title="Konfirmasi proses manual"
							description="Pastikan kamu telah memasukkan pesanan ke POS terlebih dulu"
							leftButtonText="Batal"
							rightButtonText="Konfirmasi"
							onBackdropClick={() => setManualModalOpen(false)}
							onClickLeft={() => setManualModalOpen(false)}
							onClickRight={() => {
								setManualModalOpen(false);
								onProcessOrder();
							}}
						/>
					</>
				)}
			</div>
		</>
	);
}

BottomButtons.defaultProps = {
	orderData: {},
	onProcessOrder: () => null,
	onClickCancel: () => null,
	loading: false,
};

BottomButtons.propTypes = {
	orderData: PropTypes.object,
	onProcessOrder: PropTypes.func,
	onClickCancel: PropTypes.func,
	loading: PropTypes.bool,
};

export default BottomButtons;