import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import PropTypes from 'prop-types';

import {faUser} from '@fortawesome/pro-solid-svg-icons';

import Button from 'components/Button';
import ActionModal from 'components/ActionModal';

import './index.scss';
import ContactRow from '../ContactRow';
import {isMobile} from 'utils/general';
import {markDriver} from 'utils/request/order';
import {setScanModalOpen} from 'store/actions';

function TakeawayContactCard({orderData, loading, onPickUpReadyToggled, refreshOrder}) {
	const history = useHistory();
	const dispatch = useDispatch();
	const isReadyToPickUp = orderData.status === 'pickup_ready';

	const [showPickUpReadyModal, setShowPickUpReadyModal] = useState(false);
	const [showReportDriverModal, setShowReportDriverModal] = useState(false);
	const [reportDriverFraudLoading, setReportDriverFraudLoading] = useState(false);

	const onClickButton = () => {
		if (isReadyToPickUp) {
			isMobile()
				? history.push('/scan', {isFromOrderDetail: true})
				: dispatch(setScanModalOpen(true));
		} else {
			setShowPickUpReadyModal(true);
		}
	};

	const handleReportDriverFraud = async () => {
		// setReportDriverFraudLoading(true);
		// await markDriver(orderData.shortId, orderData.firebaseKey);
		// setReportDriverFraudLoading(false);
		// setShowReportDriverModal(false);
		// refreshOrder();
	};

	return (
		<React.Fragment>
			<div className='TakeawayContactCard'>
				<ContactRow
					icon={faUser}
					description='Diambil:'
					title={orderData.userName}
					phoneNumber={orderData.userPhone}
					orderType={orderData.type}
					showReportDriverModal={() => setShowReportDriverModal(true)}
					driverMarked={orderData.driverMarked}
				/>

				{/* {!orderData.isHistory && (
					<React.Fragment>
						<div className='border-seperator' />

						<div className='button-container'>
							<Button
								className='button'
								size='lg'
								type='primary'
								color='red'
								disabled={orderData.status === 'paid'}
								loading={loading}
								text={isReadyToPickUp ? 'Scan QR Customer' : 'Pesanan Siap Diambil'}
								onClick={onClickButton}
							/>
							<div className='button-description'>
								{isReadyToPickUp
									? 'Scan kode QR customer untuk menyelesaikan pesanan'
									: 'Tekan tombol ini untuk memberi tau customer jika pesanan siap diambil'
								}
							</div>
						</div>
					</React.Fragment>
				)} */}
			</div>

			<ActionModal
				open={showPickUpReadyModal}
				title='Ubah status pesanan'
				description='Kamu akan mengubah status pesanan menjadi siap diambil. Pastikan kamu mengubah pesanan yang sesuai'
				leftButtonText='Batal'
				rightButtonText='Ubah'
				onBackdropClick={() => setShowPickUpReadyModal(false)}
				onClickLeft={() => setShowPickUpReadyModal(false)}
				onClickRight={() => {
					setShowPickUpReadyModal(false);
					onPickUpReadyToggled();
				}}
			/>
			<ActionModal
				open={showReportDriverModal}
				title="Yakin ingin menandai customer berikut sebagai driver?"
				description="Hanya tandai jika anda merasa driver melakukan kecurangan. Tim HQ akan menindaklanjuti customer berikut."
				leftButtonText="Batal"
				rightButtonText="Tandai"
				loading={reportDriverFraudLoading}
				onBackdropClick={() => setShowReportDriverModal(false)}
				onClickLeft={() => setShowReportDriverModal(false)}
				onClickRight={handleReportDriverFraud}
			/>
		</React.Fragment>
	);
}

TakeawayContactCard.defaultProps = {
	orderData: {},
	loading: false,
	onPickUpReadyToggled: () => null,
};

TakeawayContactCard.propTypes = {
	orderData: PropTypes.object,
	loading: PropTypes.bool,
	onPickUpReadyToggled: PropTypes.func,
};


export default TakeawayContactCard;