import React, {useState, useEffect} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import PulseLoader from 'react-spinners/PulseLoader';
import {useSelector} from 'react-redux';

import Container from '@material-ui/core/Container';

import {faChevronLeft} from '@fortawesome/pro-solid-svg-icons';

// Global components
import ActionModal from 'components/ActionModal';
import Header from 'components/Header';
import COLOR_PALLETES from 'assets/styles/_colors.scss';

// Global utils
import {useInterval} from 'utils/interval';
import {checkToken} from 'utils/utils';

// Local
import MainDetailCard from './components/MainDetailCard';
import DeliveryContactCard from './components/DeliveryContactCard';
import TakeawayContactCard from './components/TakeawayContactCard';
import OrderMenus from './components/OrderMenus';
import TotalPrice from './components/TotalPrice';
import BottomButtons from './components/BottomButtons';
import Cutlery from './components/Cutlery';

import {
	processOrder,
	fetchOrder,
	replaceDriver,
	togglePickupReady,
	findDriver,
} from './utils/fetch';

import './index.scss';
import {getOrders} from 'utils/request/order';
import {isEmpty} from 'lodash';

function OrderDetail() {
	const history = useHistory();
	const {shortId} = useParams();

	const [isLoading, setIsLoading] = useState(false);
	const [modalData, setModalData] = useState({type: null, open: false});

	const [orderData, setOrderData] = useState();

	const selectedOutletId = useSelector(state => state.outlet.selectedOutletId);

	const onProcessOrder = async () => {
		setIsLoading(true);
		await processOrder(orderData.firebaseKey, orderData.status);
		setIsLoading(false);
		await getOrderData();
	};

	const onPickUpReadyToggled = async () => {
		setIsLoading(true);
		await togglePickupReady(orderData.id, orderData.outletId);
		await getOrderData();
		setIsLoading(false);
	};

	const getOrderData = async () => {
		if (!selectedOutletId) return;
		await checkToken();
		const response = await getOrders({
			filter: {
				outletId: selectedOutletId,
				shortId,
			},
		});
		const orderData = response?.orders?.rows || [];
		if (!isEmpty(orderData)) {
			setOrderData(orderData[0]);
		}
	};

	const onClickDriverAction = async () => {
		setIsLoading(true);
		modalData.type === 'findDriver'
			? await findDriver(orderData.id, orderData.outletId)
			: await replaceDriver(orderData.id, orderData.outletId);
		setIsLoading(false);
		setModalData({...modalData, open: false});
		getOrderData();
	};

	useEffect(() => {
		if (selectedOutletId) {
			getOrderData();
		}
		// eslint-disable-next-line
  }, [selectedOutletId]);

	useInterval(() => {
		checkToken();
		if (selectedOutletId) getOrderData();
	}, 60000);

	return orderData ? (
		<div className="OrderDetail">
			<Header
				title={
					'Detail Pesanan ' +
	      (orderData.type === 'takeaway' ? 'Takeaway' : 'Delivery')
				}
				renderBottomBorder
				faLeftIcon={faChevronLeft}
				onClickLeftIcon={() => history.goBack()}
			/>
			<Container
				maxWidth="md"
				className="container">
				<div className="top-container">
					<MainDetailCard orderData={orderData} />

					{orderData.type !== 'takeaway' ? (
						<DeliveryContactCard
							orderData={orderData}
							onClickChangeDriver={() =>
								setModalData({type: 'changeDriver', open: true})
							}
							onClickFindDriver={() =>
								setModalData({type: 'findDriver', open: true})
							}
						/>
					) : (
						<TakeawayContactCard
							orderData={orderData}
							loading={isLoading}
							onPickUpReadyToggled={onPickUpReadyToggled}
							refreshOrder={getOrderData}
						/>
					)}

					<OrderMenus orderData={orderData} />

					<Cutlery useCutlery={!orderData.withoutCutlery} />

					<TotalPrice orderData={orderData} />
				</div>
				{/* <BottomButtons
					orderData={orderData}
					onProcessOrder={onProcessOrder}
					onClickCancel={() =>
						history.push('/cancel', {
							orderId: orderData.id,
							outletId: orderData.outletId,
						})
					}
					loading={isLoading}
				/> */}
			</Container>

			<ActionModal
				open={modalData.open}
				loading={isLoading}
				title={
					modalData.type === 'findDriver'
						? 'Cari driver sekarang?'
						: 'Ingin ganti driver?'
				}
				description={modalData.type === 'findDriver' ? 'Pastikan makanan sudah selesai sebelum memanggil driver' : ''}
				leftButtonText="Batal"
				rightButtonText={modalData.type === 'findDriver' ? 'Cari' : 'Ganti'}
				onBackdropClick={() => setModalData({...modalData, open: false})}
				onClickLeft={() => setModalData({...modalData, open: false})}
				onClickRight={onClickDriverAction}
			/>
		</div>
	) : (
		<div className="OrderDetail loading-container">
			<PulseLoader
				color={COLOR_PALLETES.sauce}
				size={16}
				margin={8} />
		</div>
	);
}

export default OrderDetail;
