import React, {useState, useEffect, useRef} from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {filter, isEmpty} from 'lodash';
import moment from 'moment';
import Firebase from 'firebase/app';
import PulseLoader from 'react-spinners/PulseLoader';

import Container from '@material-ui/core/Container';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExclamationCircle, faHistory} from '@fortawesome/pro-solid-svg-icons';

// Global components
import OrderEntry from 'components/OrderEntry';
import Header from 'components/Header';
import EmptyOrder from 'components/EmptyOrder';

// Global utils
import {checkToken} from 'utils/utils';
import {useInterval} from 'utils/interval';
import {ORDER_FILTER, ORDER_STATUS} from 'utils/constants';

// Global assets
import COLOR_PALLETES from 'assets/styles/_colors.scss';

// Local
import {checkAndShowProcessedToast, checkIfRobotIsOnline, filterData, onTriggerAutomaticProcess} from './utils';
import OrderTabBar from './TabBar';
import './index.scss';
import {getOrders} from 'utils/request/order';

function Order() {
	const isCurrentlyOffline = useRef(false);
	const database = Firebase.database();
	const history = useHistory();

	const selectedOutletId = useSelector(state => state.outlet.selectedOutletId);

	const [selectedOrderTab, setSelectedOrderTab] = useState(localStorage.getItem('selectedOrderTab') || ORDER_FILTER.PENDING_ORDER);
	const [isLoading, setIsLoading] = useState(true);
	// const [orderFirebaseData, setOrderFirebaseData] = useState([]);
	const [orderDisplayedData, setOrderDisplayedData] = useState();
	const [robotData, setRobotData] = useState({});
	const [robotJustOnlineLoading, setRobotJustOnlineLoading] = useState(false);

	// Event handler methods
	const onChangeTab = value => {
		setSelectedOrderTab(value);
		localStorage.setItem('selectedOrderTab', value);
	};

	// Methods
	const fetchOrder = async () => {
		try {
			if (!selectedOutletId) return;
			const {orders} = await getOrders({
				filter: {
					outletId: selectedOutletId,
					status: [
						ORDER_STATUS.PAID,
						ORDER_STATUS.PROCESSED,
						ORDER_STATUS.FIND_DRIVER,
						ORDER_STATUS.DRIVER_FOUND,
						ORDER_STATUS.REFIND_DRIVER,
						ORDER_STATUS.PICKUP_READY,
					],
				},
				order: 'desc',
				orderBy: 'paidAt',
				limit: null,
			});
			const groupedOrderData = filterData(orders?.rows);
			setOrderDisplayedData(groupedOrderData);
		} catch (error) {

		} finally {
			setIsLoading();
		}
	};

	// Hooks
	useEffect(() => {
		if (selectedOutletId) fetchOrder()
	}, [selectedOutletId])

	useInterval(() => {
		// Interval to update state
		if (selectedOutletId) fetchOrder()
		checkToken();
	}, 60000);

	const lastDetectedTime = robotData.lastUpdatedAt || robotData.startedAt;
	const isRobotOnline = lastDetectedTime && checkIfRobotIsOnline(lastDetectedTime);

	return (
		<div className="Order">
			<Header
				title="Pesanan"
				renderBottomBorder
				faRightIcon={faHistory}
				onClickRightIcon={() => history.push('/history')}
			/>

			<OrderTabBar
				selectedOrderTab={selectedOrderTab}
				data={orderDisplayedData}
				onChangeTab={onChangeTab} />

			<div className="order-container">
				{/* {selectedOrderTab === 'pending' && !isEmpty(robotData) && !isRobotOnline && (
					<div className="order-list-ticker sauce row">
						<div className="icon-container">
							<FontAwesomeIcon
								icon={faExclamationCircle}
								className="alert-icon"
							/>
						</div>
						Segera nyalakan kembali robot kasir agar semua pesanan pending diproses otomatis
					</div>
				)} */}
				{selectedOrderTab !== 'pending' && (
					<div className="order-list-ticker matcha row">
						Semua pesanan yang berada disini sedang diproses
					</div>
				)}

				{isLoading ? (
					<div className="loading-screen"><PulseLoader
						color={COLOR_PALLETES.sauce}
						size={16}
						margin={8} /></div>
				) : (
					<Container
						maxWidth="md"
						className="order-list">
						<div>
							{isEmpty(orderDisplayedData?.[selectedOrderTab]) ?
								<EmptyOrder tab={selectedOrderTab} /> :
								orderDisplayedData?.[selectedOrderTab]?.map((entry, i) => (
									<OrderEntry
										key={i}
										data={entry}
										isRobotOnline={isRobotOnline}
										isPendingOrder={selectedOrderTab === 'pending'}
										isProcessingOrder={robotJustOnlineLoading}
										onClick={() => history.push(`/order/${entry.shortId}`)}
										onClickManualProcess={() => history.push(`/order/${entry.shortId}`)}
										onClickAutomaticProcess={() => onTriggerAutomaticProcess(entry)}
									/>
								),
								)}
						</div>
					</Container>
				)}
				{!isEmpty(robotData) && (
					<div className={`robot-status ${isRobotOnline && 'robot-status-online'}`}>
						<div className="indicator-outer">
							<div className="indicator-inner" />
						</div>
						<span className="status-text">
							{isRobotOnline ? 'Robot Online' : 'Robot Offline'}
						</span>
						{!isRobotOnline && (
							<span className="last-updated-time">
								{'· ' + moment(lastDetectedTime).fromNow()}
							</span>
						)}
					</div>
				)}
			</div>
		</div >
	);
}

export default Order;