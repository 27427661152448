import {
  SET_AVAILABLE_OUTLETS,
  SET_SELECTED_OUTLET_ID,
  SET_SELECTED_OUTLET_DATA,
  SET_SELECTED_OUTLET,
} from '../actionTypes';

const initialState = {
  selectedOutlet: 0,
  selectedOutletId: '',
  selectedOutletData: {},
  availableOutlets: [],
};

const outlet = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_OUTLET:
      return {...state, selectedOutlet: action.selectedIndex};
    case SET_SELECTED_OUTLET_ID:
      return {...state, selectedOutletId: action.selectedOutletId};
    case SET_SELECTED_OUTLET_DATA:
      return {...state, selectedOutletData: action.selectedOutletData};
    case SET_AVAILABLE_OUTLETS:
      return {...state, availableOutlets: action.outlets};
    default:
      return state;
  }
};

export default outlet;
