import React, {useState} from 'react';
import {useDispatch} from 'react-redux';

import {Container} from '@material-ui/core';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBell, faSignOut} from '@fortawesome/pro-solid-svg-icons';

// Local
import './index.scss';
import LogoutModal from './components/LogoutModal';

// Global
import Header from 'components/Header';
import firebaseUtils from 'config/firebase';
import {setToasterData, setToasterOpen} from 'store/actions';
import {TOASTER_STATUS} from 'utils/constants';
import {version} from '../../../package.json';

function Profile() {
  const dispatch = useDispatch();
  const [logoutModalOpen, setLogoutModalOpen] = useState(false);
  let easterEggTimeout;

  const pushNotificationTest = () => {
    clearInterval(easterEggTimeout);
    dispatch(
      setToasterData({
        message: 'Notifikasi testing akan masuk dalam 5 detik',
        status: TOASTER_STATUS.SUCCESS,
      }),
    );
    dispatch(setToasterOpen(true));
    firebaseUtils.pushNewOrderNotification();
  };

  const MENUS = [
    {icon: faBell, label: 'Tes Notifikasi', action: pushNotificationTest},
    {icon: faSignOut, label: 'Keluar', action: () => setLogoutModalOpen(true)},
  ];

  return (
    <div className="Profile">
      <Header title="Akun" renderBottomBorder />

      <Container maxWidth="md" className="profile-menus-container">
        {MENUS.map(({icon, label, action}, i) => (
          <div
            key={i}
            className="profile-menu-entry row touchable-highlight"
            onClick={action}>
            <div className="icon-container row">
              <FontAwesomeIcon icon={icon} className="icon" />
            </div>
            <div className="entry-text">{label}</div>
          </div>
        ))}

        <div className="profile-hangry-version">Hangry Resto v{version}</div>
      </Container>

      <LogoutModal
        open={logoutModalOpen}
        onClose={() => setLogoutModalOpen(false)}
        onClickLogout={() => setLogoutModalOpen(false)}
      />
    </div>
  );
}

export default Profile;
