import React from 'react';
import PropTypes from 'prop-types';
import PulseLoader from 'react-spinners/PulseLoader';

import COLOR_PALLATES from 'assets/styles/_colors.scss';

import './index.scss';

function Button({type, size, color, loading, disabled, style, text, onClick, className}) {

	let buttonStyles = {};
	let textStyles = {};
	let disabledButtonStyles = {};
	let disabledTextStyles = {};

	switch (type) {
	case 'primary':
		if (color === 'white') {
			Object.assign(buttonStyles, {
				backgroundColor: COLOR_PALLATES.milk,
			});
			Object.assign(textStyles, {
				color: COLOR_PALLATES.sauce,
			});
		}
		Object.assign(disabledButtonStyles, {
			backgroundColor: COLOR_PALLATES.greyLight,
		});
		Object.assign(disabledTextStyles, {
			color: COLOR_PALLATES.milk,
		});
		break;
	case 'secondary':
		Object.assign(buttonStyles, {
			backgroundColor: 'transparent',
			borderWidth: 1,
			borderStyle: 'solid',
			borderColor: color === 'white' ? COLOR_PALLATES.milk : COLOR_PALLATES.sauce,
		});
		Object.assign(textStyles, {
			color: color === 'white' ? COLOR_PALLATES.milk : COLOR_PALLATES.sauce,
		});
		Object.assign(disabledButtonStyles, {
			borderColor: COLOR_PALLATES.greyLight,
		});
		Object.assign(disabledTextStyles, {
			color: COLOR_PALLATES.greyLight,
		});
		break;
	default:
	}

	switch (size) {
	case 'sm':
		Object.assign(buttonStyles, {
			padding: 8,
			height: 34,
		});
		Object.assign(textStyles, {
			fontFamily: 'Inter-Bold',
			fontSize: 12,
		});
		break;
	case 'md':
		Object.assign(buttonStyles, {
			paddingTop: 8,
			paddingBottom: 8,
			paddingLeft: 12,
			paddingRight: 12,
			height: 40,
		});
		break;
	case 'lg':
	default:
	}

	return (
		<div
			className={`Button row touchable-opacity ${className}`}
			style={{...buttonStyles, ...(disabled || loading ? disabledButtonStyles : {}), ...style}}
			onClick={() => !disabled && !loading && onClick()}
		>
			<div
				className='button-text'
				style={{...textStyles, ...(disabled || loading ? disabledTextStyles : {})}}>
				{loading ? (
					<PulseLoader
						color={COLOR_PALLATES.milk}
						size={8} />
				) : text}
			</div>
		</div>
	);
}

Button.defaultProps = {
	type: 'primary',
	size: 'lg',
	color: 'red',
	loading: false,
	disabled: false,
	style: {},
	className: '',
	text: 'Button',
	onClick: () => null,
};

Button.propTypes = {
	type: PropTypes.oneOf(['primary', 'secondary']),
	size: PropTypes.oneOf(['lg', 'md', 'sm']),
	color: PropTypes.oneOf(['red', 'white']),
	loading: PropTypes.bool,
	disabled: PropTypes.bool,
	style: PropTypes.object,
	className: PropTypes.string,
	text: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.object]),
	onClick: PropTypes.func,
};

export default Button;
