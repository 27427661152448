import React, {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import Axios from 'axios';
import PulseLoader from 'react-spinners/PulseLoader';

import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import './index.scss';
import {initializeApp} from 'utils/utils';
import {LOGIN_ERRORS, TOASTER_STATUS} from 'utils/constants';
import logger from 'utils/logger';
import {setToasterData, setToasterOpen} from 'store/actions';
import firebaseUtils from 'config/firebase';
import COLOR_PALLETES from 'assets/styles/_colors.scss';

function Login() {
	const history = useHistory();
	const dispatch = useDispatch();

	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');

	const [isLoading, setIsLoading] = useState(false);

	const onSubmitLogin = async () => {
		setIsLoading(true);

		try {
			const {data} = await Axios.post(
				`${process.env.REACT_APP_HANGRY_SERVICE_API}/intools/gql`,
				{
					query: `mutation Login($username: String, $password: String, $resto_app: Boolean) {
          login(username: $username, password: $password, resto_app: $resto_app) {
            token
          }
        }`,
					variables: {
						username,
						password,
						resto_app: true,
					},
				},
			);

			if (data?.errors?.[0]?.message) {
				dispatch(
					setToasterData({
						message: data.errors[0].message === LOGIN_ERRORS.WRONG_EMAIL_OR_PASSWORD
							? 'Kombinasi username atau password salah'
							: data.errors[0].message === LOGIN_ERRORS.ACCOUNT_LOCKED
								? 'Akun ini telah di blokir'
								:data.errors[0].message,
						status: TOASTER_STATUS.FAILED,
					}),
				);
				dispatch(setToasterOpen(true));
			} else {
				localStorage.setItem('loginToken', data.data.login.token);
				await firebaseUtils.signInAuthToken(data.data.login.token);

				await initializeApp(data.data.login.token);
				localStorage.setItem('shouldPromptMenu', true);
				history.push('/');
			}
		} catch (error) {
			console.error(error.message);
			logger.notify(error);
			dispatch(
				setToasterData({
					message: error?.message || 'Terjadi Kesalahan',
					status: TOASTER_STATUS.FAILED,
				}),
			);
			dispatch(setToasterOpen(true));
		} finally {
			setIsLoading(false);
		}
	};

	const onKeyDown = e => {
		if (!isLoading && e.key === 'Enter') {
			onSubmitLogin();
		}
	};

	useEffect(() => {
		const isLoggedIn = localStorage.getItem('loginToken') ? true : false;
		isLoggedIn && history.push('/');
	}, [history]);

	return (
		<div className="Login">
			<img
				src={require('assets/images/hangry.png')}
				alt="hangry-logo"
				className="logo"
			/>
			<Container
				maxWidth="sm"
				className="container">
				<div className="title">Sign in</div>
				<div className="description">Masuk ke Hangry Resto</div>
				<div className="seperator" />
				<div className="input-header">Username</div>
				<TextField
					value={username}
					onChange={e => setUsername(e.target.value)}
					onFocus={() =>
						document
							.getElementById('loginButton')
							.scrollIntoView({behavior: 'smooth'})
					}
					onKeyDown={onKeyDown}
					className="input"
					variant="outlined"
					placeholder="Isi username"
					type="text"
				/>
				<div className="input-header">Password</div>
				<TextField
					value={password}
					onChange={e => setPassword(e.target.value)}
					onFocus={() =>
						document
							.getElementById('loginButton')
							.scrollIntoView({behavior: 'smooth'})
					}
					onKeyDown={onKeyDown}
					className="input"
					variant="outlined"
					placeholder="Isi password"
					type="password"
				/>
				<Button
					id="loginButton"
					className={`button ${isLoading && 'button-disabled'}`}
					disabled={isLoading}
					variant="contained"
					onClick={() => onSubmitLogin()}>
					{isLoading ? (
						<PulseLoader
							color={COLOR_PALLETES.milk}
							size={8} />
					) : (
						'Masuk'
					)}
				</Button>
			</Container>
		</div>
	);
}

export default Login;
