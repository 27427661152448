import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

function Header(props) {

  return (
    <div className={`Header row ${props.renderBottomBorder && 'bottom-border'}`}>
      <div className='sideSection touchable-opacity' onClick={props.onClickLeftIcon}>
        {props.faLeftIcon && <FontAwesomeIcon icon={props.faLeftIcon} className='icon' />}
      </div>
      <div className='headerText'>{props.title}</div>
      <div className='sideSection touchable-opacity' onClick={props.onClickRightIcon}>
        {props.faRightIcon && <FontAwesomeIcon icon={props.faRightIcon} className='icon' />}
      </div>
    </div>
  );
}

Header.defaultProps = {
  title: 'Title',
  faLeftIcon: null,
  faRightIcon: null,
  renderBottomBorder: false,
  onClickLeftIcon: () => null,
  onPressRightIcon: () => null,
}
Header.propTypes = {
  title: PropTypes.string.isRequired,
  faLeftIcon: PropTypes.object,
  faRightIcon: PropTypes.object,
  renderBottomBorder: PropTypes.bool,
  onClickLeftIcon: PropTypes.func,
  onClickRightIcon: PropTypes.func,
}
export default Header;