import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';

function EmptyOrder({tab}) {
	return (
		<div className='EmptyOrder'>
			<div className='empty-order-description'>Belum ada pesanan {tab?.toLowerCase()}</div>
		</div>
	);
}

EmptyOrder.propTypes = {
	tab: PropTypes.string,
};

export default EmptyOrder;