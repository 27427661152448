import React from 'react';
import PropTypes from 'prop-types';
import HashLoader from 'react-spinners/HashLoader';

import {Dialog} from '@material-ui/core';

import Button from 'components/Button';
import COLOR_PALLETES from 'assets/styles/_colors.scss';

import './index.scss';

function ActionModal({open, title, description, leftButtonText, rightButtonText, loading, onBackdropClick, onClickLeft, onClickRight, className}) {
	return (
		<Dialog
			className={`ActionModal ${className}`}
			open={open}
			onBackdropClick={onBackdropClick}
			onExited={() => document.activeElement.blur()} // force focus on body so that qr listener can run
		>
			{loading ? (
				<div className='loading-container row'>
					<HashLoader color={COLOR_PALLETES.sauce} />
				</div>
			) : (
				<React.Fragment>
					<div className='title'>{title}</div>
					<div className='description'>{description}</div>
					<div className='button-container row'>
						<Button
							size='lg'
							type='secondary'
							color='red'
							text={leftButtonText}
							onClick={onClickLeft}
							className='button'
						/>
						<Button
							size='lg'
							type='primary'
							color='red'
							text={rightButtonText}
							onClick={onClickRight}
							className='button right-button'
						/>
					</div>
				</React.Fragment>
			)}
		</Dialog >
	);
}

ActionModal.defaultProps = {
	open: false,
	title: '',
	description: '',
	leftButtonText: '',
	rightButtonText: '',
	loading: false,
	onBackdropClick: () => null,
	onClickLeft: () => null,
	onClickRight: () => null,
};

ActionModal.propTypes = {
	open: PropTypes.bool,
	title: PropTypes.string,
	description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	leftButtonText: PropTypes.string,
	rightButtonText: PropTypes.string,
	loading: PropTypes.bool,
	onBackdropClick: PropTypes.func,
	onClickLeft: PropTypes.func,
	onClickRight: PropTypes.func,
};

export default ActionModal;