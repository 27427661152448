import Axios from 'axios';
import moment from 'moment-timezone';
import {get} from 'lodash';

import store from 'store';
import {setToasterData, setToasterOpen} from 'store/actions';

import {fetchSelectedOutletData} from 'utils/request/outlet';
import {TOASTER_STATUS} from 'utils/constants';
import logger from 'utils/logger';

export const fetchOutletData = async (
	setOutletOpen,
	setIsOutletOperationalHour,
	setFetchingOutletData,
	setToggleValidUntil,
) => {
	setFetchingOutletData(true);

	const result = await fetchSelectedOutletData();
	if (result) {
		setOutletOpen(result.openHours.open);
		const timezone = result.timezone || 'Asia/Jakarta';

		const currentMoment = moment().tz(timezone);
		let currentDay = currentMoment.day();
		if (currentMoment.hour() < 7) currentDay -= 1; // if current outlet time is below 7 AM (UTC+7), use previous day's openHour instead

		const currentDayKey = moment().day(currentDay).format('ddd').toLowerCase();
		const currentDayData = result.openHours.days.find(
			entry => entry.day === currentDayKey,
		);

		const openCloseHour = currentDayData.openHours
			.split('-')
			.map(time =>
				moment(time, 'HH:mm').tz(timezone, true).day(currentDayKey),
			);
		// if the closing hours is before the opening hours, then it means that it opens till past midnight
		if (openCloseHour[1].isBefore(openCloseHour[0])) openCloseHour[1].add(1, 'days');

		setIsOutletOperationalHour(
			currentDayData.isOpen &&
				currentMoment.isBetween(
					openCloseHour[0].subtract(1, 'seconds'),
					openCloseHour[1],
				),
		);
		setToggleValidUntil(openCloseHour[1]);
	}
	setFetchingOutletData(false);
};

export const toggleOutletOpenClose = async (
	currentOpenStatus,
	toggleValidUntil,
	setOutletOpen,
	setTogglingOutletOpen,
) => {
	setTogglingOutletOpen(true);
	try {
		const outletId = store.getState().outlet.selectedOutletId;
		// toggle menu availability
		const {data} = await Axios.post(
			`${process.env.REACT_APP_HANGRY_SERVICE_API}/intools/gql`,
			{
				query: `mutation Toggle($outletId: uuid!, $isOpen: Boolean, $validUntil: formattedDatetime) {
				toggleOutlet(outletId: $outletId, isOpen: $isOpen, validUntil: $validUntil) {
					success,
					message
				}
			}`,
				variables: {
					outletId,
					isOpen: !currentOpenStatus,
					validUntil: toggleValidUntil.format(),
				},
			},
			{
				headers: {
					Authorization: localStorage.getItem('loginToken'),
				},
			},
		);

		if (get(data, 'errors[0].extensions.code') === 'UNAUTHENTICATED')
			return window.location.reload();
		if (data.errors) throw new Error(JSON.stringify(data.errors));

		if (data.data.toggleOutlet.success === 'false')
			throw new Error(data.data.toggleOutlet.message);

		// show snackbar
		store.dispatch(
			setToasterData({
				message: `Outlet berhasil ${!currentOpenStatus ? 'dibuka' : 'ditutup'}`,
				status: TOASTER_STATUS.SUCCESS,
			}),
		);
		store.dispatch(setToasterOpen(true));

		// toggle if succeed
		setOutletOpen(!currentOpenStatus);
	} catch (error) {
		console.error(error);
		logger.notify(error);

		// show snackbar
		store.dispatch(
			setToasterData({
				message: `Outlet gagal ${!currentOpenStatus ? 'dibuka' : 'ditutup'}`,
				status: TOASTER_STATUS.FAILED,
			}),
		);
		store.dispatch(setToasterOpen(true));
	} finally {
		setTogglingOutletOpen(false);
	}
};
