import React, {useState} from 'react';
import {useHistory} from 'react-router';

// Global
import QRDecoder from 'components/QRDecoder';
import {scanPickUpQR} from 'utils/request/scanQR';

import ScanOverlay from './components/ScanOverlay';

import './style.scss';


let lastCode;
let isSubmitting = false;

function ScanQRPage() {
  const history = useHistory();
  const {isFromOrderDetail} = history.location.state || {};

  const [isLoading, setIsLoading] = useState(false);

  const pickUpOrder = async (QRPayload) => {
    setIsLoading(true); 
    isSubmitting = true;
    // Why don't just use one? the current QRDecoder's is not very optimized so if we use the state inside the submitQRPayload function, 
    // it will return stale state. And the second problem is that the isSubmitting value doesn't rerender
    // this is the proposed temporary solution

    const shortId = await scanPickUpQR(QRPayload);
    if (shortId) {
      if (isFromOrderDetail) await history.goBack();
      history.replace('/order/' + shortId);
    }
    isSubmitting = false;
    setIsLoading(false);
  }

  const submitQRPayload = (newCode) => {
    if (lastCode === newCode || isSubmitting) return;
    lastCode = newCode;
    pickUpOrder(newCode);
    setTimeout(() => lastCode = null, 500);
  }

  return (
    <div className="ScanQR">
      <div className="camera-container">
        <QRDecoder onDecoded={submitQRPayload}></QRDecoder>
        <ScanOverlay isLoading={isLoading} />
      </div>
    </div>
  )
}

export default ScanQRPage;