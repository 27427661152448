import React from 'react';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExclamationTriangle} from '@fortawesome/pro-solid-svg-icons';

import ActionModal from 'components/ActionModal';
import COLOR_PALLETES from 'assets/styles/_colors.scss';

import './index.scss';


function ToggleMenuModal({open, loading, activeLabel, activeImpacted, isCurrentlyAvailable, changeToggle, closeDialog}) {

  return (
      <ActionModal
        open={open}
        className='ToggleMenuModal'
        loading={loading}
        title='Ubah ketersediaan stok'
        description={
          <div>
            Kamu akan mengubah stok&nbsp;
            <span className='bold'>
              {activeLabel}
              {activeImpacted?.length > 0 && ` dan ${activeImpacted?.length} menu`}
            </span>
            &nbsp;menjadi {isCurrentlyAvailable ? 'habis' : 'tersedia'}
            {activeImpacted?.length > 0 &&
              <div className='package-menu-warning row'>
                <div className='icon-container row'>
                  <FontAwesomeIcon icon={faExclamationTriangle} color={COLOR_PALLETES.mentaiDark} />
                </div>
                <div className='text-container'>
                  Menu yang terhubung: {activeImpacted?.map(e => e.menuName).join(', ')}
                </div>
              </div>
            }
          </div>
        }
        leftButtonText='Tidak'
        rightButtonText='Ubah'
        onBackdropClick={closeDialog}
        onClickLeft={closeDialog}
        onClickRight={changeToggle}
      />
  )
}

export default ToggleMenuModal;