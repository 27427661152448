import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import Firebase from 'firebase/app';
import PulseLoader from 'react-spinners/PulseLoader';

import Container from '@material-ui/core/Container';

import {faChevronLeft} from '@fortawesome/pro-solid-svg-icons';

import './index.scss';
import Header from 'components/Header';
import OrderEntry from 'components/OrderEntry';
import EmptyOrder from 'components/EmptyOrder';
import COLOR_PALLETES from 'assets/styles/_colors.scss';

import {checkToken} from 'utils/utils';
import {useInterval} from 'utils/interval';
import {ORDER_STATUS} from 'utils/constants';
import {getOrders} from 'utils/request/order';
import {filterData} from 'views/Order/utils';

function History() {
	const database = Firebase.database();
	const history = useHistory();

	const selectedOutletId = useSelector(state => state.outlet.selectedOutletId);

	const [isLoading, setIsLoading] = useState(true);
	const [historyData, setHistoryData] = useState([]);

	const onClickOrder = orderData => {
		history.push(`/order/${orderData.shortId}`);
	};

	const fetchHistory = async () => {
		try {
			if (!selectedOutletId) return;
			const {orders} = await getOrders({
				filter: {
					outletId: selectedOutletId,
					status: [
						ORDER_STATUS.PICKED_UP,
						ORDER_STATUS.DELIVERY,
						ORDER_STATUS.DELIVERED,
						ORDER_STATUS.CANCELLED,
						ORDER_STATUS.REFUND,
					],
				},
				order: 'desc',
				orderBy: 'paidAt',
				limit: 20,
			});
			setHistoryData(orders?.rows);
		} catch (error) {

		} finally {
			setIsLoading();
		}
	};

	// Firebase Real Time Database
	// Hooks
	useEffect(() => {
		if (selectedOutletId) fetchHistory();
	}, [selectedOutletId]);

	useInterval(() => {
		// Interval to update state
		checkToken();
	}, 10000);
	return (
		<div className='History'>
			<Header
				title='Riwayat Pesanan'
				renderBottomBorder
				faLeftIcon={faChevronLeft}
				onClickLeftIcon={() => history.goBack()} />

			{isLoading ? (
				<div className='loading-screen'><PulseLoader
					color={COLOR_PALLETES.sauce}
					size={16}
					margin={8} /></div>
			) : (
				<Container
					maxWidth='md'
					className='history-list'>
					{historyData.length > 0 ?
						historyData.map((entry, i) => {
							return (
								<OrderEntry
									key={i}
									data={entry}
									isHistory
									onClick={() => onClickOrder(entry)} />
							);
						}) :
						<EmptyOrder />
					}
				</Container>
			)}
		</div >
	);
}

export default History;