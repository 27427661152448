import React, {useEffect} from 'react';

import jsQR from 'jsqr';

import './style.scss';
import {isEmpty} from 'lodash';

const previewStyle = {
  display: 'block',
  position: 'relative',
  overflow: 'hidden',
  width: '100%',
  height: '100%',
};

const videoPreviewStyle = {
  ...previewStyle,
  objectFit: 'cover',
}

const hiddenStyle = {
  display: 'none'
}

const refs = {};
const setRef = key => element => {
  refs[key] = element;
}

let stream;
let allowCb = true;

function QRDecoder(props) {
  const {onDecoded, rate, callbackRate} = props;
  const tick = (cb) => {
    const {video, canvas, canvasElement} = refs;
    if (!video) return;

    if (video.readyState === video.HAVE_ENOUGH_DATA) {
      canvasElement.height = video.videoHeight;
      canvasElement.width = video.videoWidth;
      canvas.drawImage(video, 0, 0, canvasElement.width, canvasElement.height);
      const imageData = canvas.getImageData(0, 0, canvasElement.width, canvasElement.height);
      const code = jsQR(imageData.data, imageData.width, imageData.height, {inversionAttempts: 'dontInvert'});
      if (code && code.data) {

        if (allowCb) {
          cb(code.data);
          allowCb = false;
          setTimeout(() => {
            allowCb = true;
          }, callbackRate || 3000);
        }
      }
    }

    setTimeout(() => requestAnimationFrame(() => tick(cb)), rate || 200);
  }

  const startStream = async (devices) => {
    const {video} = refs;
    video.playsInline = true;

    // Handle case for devices with multiple back cameras
    const options = devices.sort((a,b) => a.label > b.label ? 1 : -1).map(device => {
      return {
        deviceId : {
          exact : device.deviceId
        },
        facingMode : 'environment'
      }
    });

    // Fallback default to back camera if possible
    options.push({
      facingMode: 'environment'
    });
    // Else, whatever videoinput source will do
    options.push(true);

    for (let option of options) {
      stream = await navigator.mediaDevices.getUserMedia({
        video : option,
        audio : false,
      });

      try {
        video.srcObject = stream;
        video.play();

        return;
      } catch (e) {
        console.log('Failed starting stream with option', option);
      }
    }
  }

  const startVideo = async (cb) => {
    const devices = await navigator.mediaDevices.enumerateDevices();
    const backCameras = devices.filter(device => device.kind === 'videoinput' && isEmpty(device.deviceId));
    startStream(backCameras);

    requestAnimationFrame(() => tick(cb));
  }

  useEffect(() => {
    if (!refs.canvas) {
      const canvas = refs.canvasElement.getContext('2d');
      refs.canvas = canvas;
    }
    startVideo(onDecoded);

    return () => {
      try {
        refs.canvas = null;
        stream.getTracks().forEach((track) => track.stop());
      } catch (err) {
        console.log(err);
      }
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="QRDecoder">
      <canvas className="camera-container" style={hiddenStyle} ref={setRef('canvasElement')} />
      <video className="camera-container" style={videoPreviewStyle} ref={setRef('video')} />
    </div>
  )
}

export default QRDecoder;
