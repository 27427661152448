import firebase from 'firebase/app';
import 'firebase/messaging';
import 'firebase/database';
import 'firebase/auth';

import jwt_decode from 'jwt-decode';
import Axios from 'axios';

class Firebase {
	firebase_configuration = {
		apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
		authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
		databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
		projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
		storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
		messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
		appId: process.env.REACT_APP_FIREBASE_APP_ID,
		measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
	};

	constructor() {
		console.log('Firebase initializing....');
		firebase.initializeApp(this.firebase_configuration);
		console.log('Firebase initialized!');
	}

	async getMessagingToken() {
		const messaging = firebase.messaging();
		const token = await messaging.getToken();
		this.notificationToken = token;
		return token;
	}

	async getFirebaseMessagingToken() {
		try {
			if (!('Notification' in window)) {
				alert('This browser does not support desktop notification');
			}
			// Let's check whether notification permissions have already been granted
			else if (Notification.permission === 'granted') {
				return this.getMessagingToken();
			} else {
				// Otherwise, we need to ask the user for permission
				await Notification.requestPermission().then(permission => {
					// If the user accepts, let's create a notification
					if (permission === 'granted') {
						return this.getMessagingToken();
					}
				});
			}
		} catch (error) {
			console.error(error);
		}
	}

	async signInAuthToken(jwtToken) {
		await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
		const decoded = jwt_decode(jwtToken);
		return firebase
			.auth()
			.signInWithCustomToken(decoded.session.firebase_token);
	}

	async signOutAuthToken() {
		await firebase.auth().signOut();
	}

	pushNewOrderNotification() {
		Axios.post(
			'https://fcm.googleapis.com/fcm/send',
			{
				to: this.notificationToken,
				notification: {
					body: 'Hangry!',
					title: 'Testing Notification',
					image:
						'https://cdn.techinasia.com/data/images/8fDdOv8C9Ly3izcfULDp1WAnKalmxtZxjQgYoCJp.jpeg',
				},
			},
			{
				headers: {
					Authorization: process.env.REACT_APP_FIREBASE_AUTH_PUSH_NOTIFICATION,
				},
			},
		);
	}
}

export default new Firebase();
