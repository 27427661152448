import React from 'react';
import groupBy from 'lodash/groupBy';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import {numberToRupiah, upperCaseFirstLetter} from 'utils/general';

import './index.scss';

function OrderMenus({orderData}) {

	const groupedOrders = orderData?.items && groupBy(orderData.items, order => order.brandName);

	const optionGroupFlatter = optionGroups => {
		const output = [];
		optionGroups.forEach(optionGroup => {
			optionGroup.options.forEach(option => {
				output.push(option);
			});
		});
		return output;
	};

	const renderSubmenusText = (options, renderPrice = true) => {
		if (options && options.length > 0) {
			return options.map((option, i) => (
				<div
					key={i}
					className='item-sub-row row'>
					<div className='item-sub'>{upperCaseFirstLetter(option.menuLabel)}</div>
					{renderPrice && <div className='item-price'>+{numberToRupiah(option.price)}</div>}
				</div>
			),
			);
		}
		return (
			<div className='item-sub-row row'>
				<div className='item-sub'>Tanpa Tambahan</div>
			</div>
		);
	};

	const renderSubMenuAndNotes = (optionGroups, notes, packageMenuName = '', index) => {
		const isPackage = !isEmpty(packageMenuName);
		const flattenOptionGroup = optionGroupFlatter(optionGroups);
		return (
			<React.Fragment key={index}>
				<div className="item-sub-container">
					<div className='item-sub-title'>{!isPackage ? 'Tambahan' : 'Menu ' + (index + 1)}:</div>
					{isPackage && (
						<div className='item-sub-row row'>
							<div className='item-sub-menu-name'>{packageMenuName}</div>
							<div className='item-price'>+Rp0</div>
						</div>
					)}
					{renderSubmenusText(flattenOptionGroup, !isPackage)}
				</div>
				{notes ? (
					<div className="item-sub-container no-gap">
						<div className='item-sub-title'>Catatan:</div>
						<div className="item-sub">{notes}</div>
					</div>
				)
					: null
				}
			</React.Fragment>
		);
	};

	const renderMenuDetail = item => {
		const isPackage = item.packages && item.packages.length > 0;
		return isPackage
			? item.packages.map((packageEntry, i) =>
				renderSubMenuAndNotes(packageEntry.submenus, packageEntry.notes, packageEntry.menuName, i),
			)
			: renderSubMenuAndNotes(item.optionGroups, item.notes);
	};

	return (
		<div className='OrderMenus'>
			<div className='order-header'>Detail Pesanan</div>
			{Object.keys(groupedOrders).map((brand, i) => {
				return (
					<React.Fragment key={i}>
						<div className='brand-name'>{brand}</div>
						{groupedOrders[brand].map((item, j) => {
							return (
								<div
									key={j}
									className="order-item-container row">
									<div className="quantity">{item.quantity}x</div>
									<div className="item-details">
										<div className='item-name-container row'>
											<div className="item-name">{item.menuName}</div>
											<div className='item-price'>{numberToRupiah(item.menuPrice)}</div>
										</div>
										{renderMenuDetail(item)}
									</div>
								</div>
							);
						},
						)}
						<div
							className='render-seperator'
							style={Object.keys(groupedOrders).length - 1 === i ? {display: 'none'} : {}} />
					</React.Fragment>
				);
			})}
		</div>
	);
}

OrderMenus.defaultProps = {
	orderData: {},
};

OrderMenus.propTypes = {
	orderData: PropTypes.object,
};


export default OrderMenus;