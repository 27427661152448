import React from 'react';
import {useSelector} from 'react-redux';

import {unsubscribeOutlet} from 'utils/request/outlet';
import firebaseUtils from 'config/firebase';
import ActionModal from 'components/ActionModal';

function LogoutModal({open, onClose}) {
  const selectedOutlet = useSelector(state => state.outlet.selectedOutlet);

  const onClickLogout = async () => {
    onClose();
    await unsubscribeOutlet(selectedOutlet);
    await firebaseUtils.signOutAuthToken();
    localStorage.clear();
    window.location.reload();
  }

  return (
    <ActionModal
      open={open}
      title='Yakin ingin keluar?'
      description='Kamu perlu masuk lagi jika ingin menggunakan Hangry Resto'
      leftButtonText='Batal'
      rightButtonText='Keluar'
      onBackdropClick={onClose}
      onClickLeft={onClose}
      onClickRight={onClickLogout}
    />
  )
}



export default LogoutModal;