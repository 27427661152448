import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useDispatch} from 'react-redux';

import {BottomNavigation, BottomNavigationAction} from '@material-ui/core';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
	faStore as faStoreSolid,
	faBook as faBookSolid,
	faReceipt as faReceiptSolid,
	faUser as faUserSolid,
} from '@fortawesome/pro-solid-svg-icons';
import {
	faStore,
	faBook,
	faReceipt,
	faUser,
} from '@fortawesome/pro-regular-svg-icons';

import {checkNotificationPermission} from 'utils/utils';
import {isMobile} from 'utils/general';
import ScanSvg from 'assets/icons/scan.svg';
import {setScanModalOpen} from 'store/actions';

import './index.scss';

function BottomNavigationBar({renderBottomBar}) {
	const history = useHistory();
	const dispatch = useDispatch();

	const [currentValue, setCurrentValue] = useState(
		history.location.pathname.replace('/', ''),
	);

	history.listen(location => {
		setCurrentValue(location.pathname.replace('/', ''));
	});

	// Handle bottom tab button pressed navigation
	const handleBottomTabPress = (event, pressedValue) => {
		if (!isMobile() && pressedValue === 'scan') {
			dispatch(setScanModalOpen(true));
		} else {
			history.push('/' + pressedValue);
		}
		checkNotificationPermission();
	};

	const renderIcon = (isActive, activeIcon, inActiveIcon) => (
		<div className="bottom-bar-icon-container">
			<FontAwesomeIcon
				className="bottom-bar-icon"
				icon={isActive ? activeIcon : inActiveIcon}
			/>
		</div>
	);

	const sharedProps = {
		showLabel: true,
		onFocus: () => document.activeElement.blur(), // force focus on body so that qr listener can run
	};

	const bottomButtons = [
		{
			label: 'Outlet',
			value: '',
			icon: renderIcon(currentValue === '', faStoreSolid, faStore),
		},
		{
			label: 'Pesanan',
			value: 'order',
			icon: renderIcon(currentValue === 'order', faReceiptSolid, faReceipt),
		},
		// {
		//   label: 'Scan',
		//   value: 'scan',
		//   icon: (
		//     <div className="bottom-bar-icon-container">
		//       <img src={ScanSvg} alt="scan" />
		//     </div>
		//   ),
		//   className: 'scan-bar',
		// },
		// {
		//   label: 'Menu',
		//   value: 'menu',
		//   icon: renderIcon(currentValue === 'menu', faBookSolid, faBook),
		// },
		{
			label: 'Akun',
			value: 'profile',
			icon: renderIcon(currentValue === 'profile', faUserSolid, faUser),
		},
	];

	return (
		<BottomNavigation
			value={currentValue}
			style={{display: renderBottomBar ? 'flex' : 'none'}}
			onChange={handleBottomTabPress}
			className="BottomNavigationBar">
			{bottomButtons.map((button, i) => (
				<BottomNavigationAction
					key={i}
					{...button}
					{...sharedProps} />
			))}
		</BottomNavigation>
	);
}

export default BottomNavigationBar;
