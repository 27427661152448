import {
  SET_USER_DATA,
  SET_FIREBASE_TOKEN,
  SET_AVAILABLE_OUTLETS,
  SET_SELECTED_OUTLET,
  SET_SELECTED_OUTLET_ID,
  SET_FIREBASE_MESSAGING_TOKEN,
  SET_TOASTER_OPEN,
  SET_TOASTER_DATA,
  SET_SCAN_MODAL_OPEN,
  SET_NOTIFICATION_OFF_MODAL_OPEN,
  SET_UNAVAILABLE_MENU_COUNT,
  SET_SELECTED_OUTLET_DATA,
} from './actionTypes';

export const setUserData = (payload) => {
  return function (dispatch) {
    dispatch({type: SET_USER_DATA, payload});
  };
};
export const setFirebaseToken = (token) => {
  return function (dispatch) {
    dispatch({type: SET_FIREBASE_TOKEN, token});
  };
};
export const setFirebaseMessagingToken = (token) => {
  return function (dispatch) {
    dispatch({type: SET_FIREBASE_MESSAGING_TOKEN, token});
  };
};
export const setSelectedOutlet = (selectedIndex) => {
  return function (dispatch) {
    dispatch({type: SET_SELECTED_OUTLET, selectedIndex});
  };
};
export const setSelectedOutletId = (selectedOutletId) => {
  return function (dispatch) {
    dispatch({type: SET_SELECTED_OUTLET_ID, selectedOutletId});
  };
};
export const setSelectedOutletData = (selectedOutletData) => {
  return function (dispatch) {
    dispatch({type: SET_SELECTED_OUTLET_DATA, selectedOutletData});
  };
};
export const setAvailableOutlets = (outlets) => {
  return function (dispatch) {
    dispatch({type: SET_AVAILABLE_OUTLETS, outlets});
  };
};
export const setToasterOpen = (open) => {
  return function (dispatch) {
    dispatch({type: SET_TOASTER_OPEN, open});
  };
};
export const setToasterData = (data) => {
  return function (dispatch) {
    dispatch({type: SET_TOASTER_DATA, data});
  };
};
export const setScanModalOpen = (open) => {
  return function (dispatch) {
    dispatch({type: SET_SCAN_MODAL_OPEN, open});
  };
};
export const setNotificationOffModalOpen = (open) => {
  return function (dispatch) {
    dispatch({type: SET_NOTIFICATION_OFF_MODAL_OPEN, open});
  };
};
export const setUnavailableMenuCount = (count) => {
  return function (dispatch) {
    dispatch({type: SET_UNAVAILABLE_MENU_COUNT, count});
  };
};
