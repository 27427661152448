import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
	faChevronRight,
	faClipboardList,
} from '@fortawesome/pro-solid-svg-icons';

import {fetchUnavailableMenus} from 'utils/request/menu';
import {setUnavailableMenuCount} from 'store/actions';

import ActionModal from 'components/ActionModal';

import './index.scss';

function OutOfStockCard() {
	const history = useHistory();
	const dispatch = useDispatch();

	const [promptModalOpen, setPromptModalOpen] = useState(false);

	const selectedOutletId = useSelector(
		state => state.outlet.selectedOutletId,
	);
	const unavailableMenuCount = useSelector(
		state => state.menu.unavailableMenuCount,
	);

	const onClosePromtModal = () => {
		localStorage.removeItem('shouldPromptMenu');
		setPromptModalOpen(false);
	};

	const onNavigateToUnavailableMenu = () => {
		promptModalOpen && onClosePromtModal();
		history.push('/menu/turnedoffmenus', {
			brandName: 'Daftar Stok Habis',
		});
	};

	const getUnavailableCount = async () => {
		const result = await fetchUnavailableMenus();
		const outOfStockCount = result ? Object.values(result).flat().length : 0;
		dispatch(setUnavailableMenuCount(outOfStockCount));

		const shouldPromptMenu = localStorage.getItem('shouldPromptMenu');
		if (shouldPromptMenu && outOfStockCount > 0) {
			setPromptModalOpen(true);
		}
	};

	useEffect(() => {
		if (selectedOutletId) {
			getUnavailableCount();
		}
  }, [selectedOutletId]); //eslint-disable-line

	return (
		<React.Fragment>
			<div
				className="OutOfStockCard row touchable-highlight"
				onClick={onNavigateToUnavailableMenu}>
				<div className="icon-container row">
					<FontAwesomeIcon
						icon={faClipboardList}
						className="icon" />
				</div>
				<div className="text-container">
					<div className="title row">
						Daftar stok habis
						{unavailableMenuCount > 0 && (
							<div className="count-indicator row">{unavailableMenuCount}</div>
						)}
					</div>
					<div className="description">
						{`${
							unavailableMenuCount > 0
								? `Ada ${unavailableMenuCount} menu yang stoknya habis`
								: 'Semua stok habis ada disini'
						}. Perbarui statusnya jika telah tersedia`}
					</div>
				</div>
				<div className="chevron-right-container row">
					<FontAwesomeIcon
						icon={faChevronRight}
						className="icon" />
				</div>
			</div>
			<ActionModal
				open={promptModalOpen}
				title="Perbarui stok habis"
				description={`Ada ${unavailableMenuCount} menu yang stoknya habis. Perbarui statusnya jika telah tersedia`}
				leftButtonText="Nanti Saja"
				rightButtonText="Perbarui"
				onBackdropClick={onClosePromtModal}
				onClickLeft={onClosePromtModal}
				onClickRight={onNavigateToUnavailableMenu}
			/>
		</React.Fragment>
	);
}

export default OutOfStockCard;
