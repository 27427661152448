import Axios from 'axios';
import store from 'store';
import {get} from 'lodash';

import logger from 'utils/logger';
import {TOASTER_STATUS} from 'utils/constants';
import {
	setSelectedOutlet,
	setSelectedOutletData,
	setToasterData,
	setToasterOpen,
} from 'store/actions';

export const subscribeOutlet = async index => {
	const outlets = store.getState().outlet.availableOutlets;
	const firebaseMessagingToken = store.getState().auth.firebaseMessagingToken;
	try {
		store.dispatch(setSelectedOutlet(index));

		const {data} = await Axios.post(
			`${process.env.REACT_APP_HANGRY_SERVICE_API}/intools/gql`,
			{
				query: `mutation Subscribe($token: String, $outletId: String) {
        subscribe(token: $token, outletId: $outletId) {
          success
        }
      }`,
				variables: {
					token: firebaseMessagingToken,
					outletId: outlets[index].id,
				},
			},
			{
				headers: {
					Authorization: localStorage.getItem('loginToken'),
				},
			},
		);

		if (get(data, 'errors[0].extensions.code') === 'UNAUTHENTICATED')
			return window.location.reload();
		if (data.errors) throw new Error(JSON.stringify(data.errors));

		console.log(
			`Subscribe outlet ${outlets[index].name} ${outlets[index].id} success`,
		);
	} catch (error) {
		console.error(error);
		logger.notify(error);
		console.log(
			`Subscribe outlet ${outlets[index].name} ${outlets[index].id} failed`,
		);
	}
};

export const unsubscribeOutlet = async index => {
	const outlets = store.getState().outlet.availableOutlets;
	const firebaseMessagingToken = store.getState().auth.firebaseMessagingToken;
	try {
		const {data} = await Axios.post(
			`${process.env.REACT_APP_HANGRY_SERVICE_API}/intools/gql`,
			{
				query: `mutation Unsubscribe($token: String, $outletId: String) {
        unsubscribe(token: $token, outletId: $outletId) {
          success
        }
      }`,
				variables: {
					token: firebaseMessagingToken,
					outletId: outlets[index].id,
				},
			},
			{
				headers: {
					Authorization: localStorage.getItem('loginToken'),
				},
			},
		);

		if (get(data, 'errors[0].extensions.code') === 'UNAUTHENTICATED')
			return window.location.reload();
		if (data.errors) throw new Error(JSON.stringify(data.errors));

		console.log(
			`Unsubscribe outlet ${outlets[index].name} ${outlets[index].id} success`,
		);
	} catch (error) {
		console.error(error);
		logger.notify(error);
		if (outlets[index]?.name && outlets[index]?.id) {
			console.log(
				`Unsubscribe outlet ${outlets[index].name} ${outlets[index].id} failed`,
			);
		}
	}
};

export const fetchSelectedOutletData = async () => {
	try {
		const outletId = store.getState().outlet.selectedOutletId;
		// fetch menu data filtered by brandId and outletId
		const {data} = await Axios.post(
			`${process.env.REACT_APP_HANGRY_SERVICE_API}/intools/gql`,
			{
				query: `query Outlet($outletId: String) {
        outlets(filter: {id: $outletId}) {
          rows {
            id
            openHours
            isOpen
            isDelivery
            isDinein
						timezone
          }
        }
      }`,
				variables: {
					outletId,
				},
			},
			{
				headers: {
					Authorization: localStorage.getItem('loginToken'),
				},
			},
		);

		if (get(data, 'errors[0].extensions.code') === 'UNAUTHENTICATED')
			return window.location.reload();
		if (data.errors) throw new Error(JSON.stringify(data.errors));

		store.dispatch(setSelectedOutletData(data.data.outlets.rows[0]));
		return data.data.outlets.rows[0];
	} catch (error) {
		console.error(error);
		logger.notify(error);

		// show snackbar
		store.dispatch(
			setToasterData({
				message: 'Gagal memuat detail outlet',
				status: TOASTER_STATUS.FAILED,
			}),
		);
		store.dispatch(setToasterOpen(true));
	}
};
