import Axios from 'axios';
import store from 'store';
import {get} from 'lodash';
import firebase from 'firebase/app';

import {setToasterData, setToasterOpen} from 'store/actions';
import logger from 'utils/logger';
import {TOASTER_STATUS} from '../constants';

export const markDriver = async (shortId, firebaseKey) => {
	try {
		const {data} = await Axios.post(
			`${process.env.REACT_APP_HANGRY_SERVICE_API}/intools/gql`,
			{
				query: `mutation markDriver($shortId: String) {
        markDriver(shortId: $shortId) {
          success,
        }
      }`,
				variables: {shortId},
			},
			{
				headers: {
					Authorization: localStorage.getItem('loginToken'),
				},
			},
		);

		if (get(data, 'errors[0].extensions.code') === 'UNAUTHENTICATED')
			return window.location.reload();
		if (data.errors) throw new Error(JSON.stringify(data.errors));

		if (data.data.markDriver.success) {
			let firebaseDBPath = null;
			const outletId = store.getState().outlet.selectedOutletId;

			// if shortId is not equal to firebaseKey, its active order else completed order (history)
			shortId !== firebaseKey
				? (firebaseDBPath = `active_orders/${firebaseKey}`)
				: (firebaseDBPath = `history_orders/${outletId}/${firebaseKey}`);

			await firebase
				.database()
				.ref(firebaseDBPath)
				.update(
					{
						driverMarked: true,
					},
					error => {
						if (error) {
							// Toaster
							store.dispatch(
								setToasterData({
									message: 'Orderan gagal ditandai',
									status: TOASTER_STATUS.ERROR,
								}),
							);
						} else {
							// Toaster
							store.dispatch(
								setToasterData({
									message: 'Orderan berhasil ditandai',
									status: TOASTER_STATUS.SUCCESS,
								}),
							);
						}
						store.dispatch(setToasterOpen(true));
					},
				);
		}
	} catch (error) {
		console.error(error);
		logger.notify(error);
	}
};

export const getOrders = async params => {
	try {
		const {filter, order, orderBy, limit, page} = params;
		const {data} = await Axios.post(
			`${process.env.REACT_APP_HANGRY_SERVICE_API}/intools/gql`,
			{
				query: `
				query getOrders($filter: OrderFilter, $order: String, $orderBy: String, $limit: Int, $page: Int) {
					orders(filter: $filter, order: $order, orderBy: $orderBy, page: $page, limit: $limit) {
						count
						rows {
							amount
							userName
							userPhone
							id
							items {
								brandName
								menuName
								menuPrice
								quantity
								notes
								optionGroups {
									optionGroupId
									optionGroupLabel
									options {
										menuLabel
									}
								}
							}
							type
							outletId
							processedAt
							promoAmount
							shortId
							status
							totalAmount
							paidAt
							processedAt
							deliveryFee
							deliveryPromoAmount
							withoutCutlery
							deliveryId
						}
					}
				}
			`,
				variables: {filter, order, orderBy, limit, page},
			},
			{
				headers: {
					Authorization: localStorage.getItem('loginToken'),
				},
			},
		);
		if (get(data, 'errors[0].extensions.code') === 'UNAUTHENTICATED')
			return window.location.reload();
		if (data.errors) throw new Error(JSON.stringify(data.errors));

		return data.data;
	} catch (error) {
		console.error(error);
		logger.notify(error);
	}
};