import React, {useEffect, useState} from 'react';
import {Switch, Route, useHistory} from 'react-router-dom';
import firebase from 'firebase/app';
import moment from 'moment';
import 'moment/locale/id';
import Bugsnag from '@bugsnag/js';
import {isEmpty} from 'lodash';

// Global Utils and Miscs
import 'utils/logger';
import {checkToken, initializeApp} from 'utils/utils';

// Global Component
import Toaster from 'components/Toaster';
import BottomNavigationBar from 'components/BottomNavigationBar';
import QRDeviceListener from 'components/QRDeviceListener';
import ScanModal from 'components/ScanModal';
import NotificationOffModal from 'components/NotificationOffModal';

// View Component
import Login from './views/Login';
import Outlet from './views/Outlet';
import Order from './views/Order';
import History from './views/History';
import OrderDetail from './views/OrderDetail';
import CancelOrder from './views/CancelOrder';
import ScanQR from './views/ScanQR';
import Menu from './views/Menu';
import MenuList from './views/Menu/MenuList';
import Profile from './views/Profile';
import Scheduler from './views/Scheduler';

import './App.scss';

const bottomBarRoutes = ['', 'menu', 'order', 'profile'];

function App() {
	const history = useHistory();

	const [renderBottomBar, setRenderBottomBar] = useState(
		bottomBarRoutes.includes(history.location.pathname.replace('/', '')),
	);

	// Scroll to top on every page change
	history.listen(location => {
		setRenderBottomBar(
			bottomBarRoutes.includes(location.pathname.replace('/', '')),
		);
		window.scrollTo(0, 0);
	});

	const validateLogin = async () => {
		const loginToken = localStorage.getItem('loginToken');
		if (loginToken) {
			const tokenValid = await checkToken();
			if (tokenValid) {
				firebase.auth().onAuthStateChanged(function (user) {
					if (user) {
						initializeApp(loginToken);
					} else {
						localStorage.clear();
						history.push('/login');
					}
				});
			}
		} else {
			localStorage.clear();
			history.push('/login');
		}
	};

	useEffect(() => {
		moment.locale('id');
		validateLogin();
  }, []); //eslint-disable-line

	const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

	return (
		<ErrorBoundary>
			<div className="App">
				<div
					className="content"
					style={!renderBottomBar ? {marginBottom: 0} : {}}>
					<Switch>
						<Route
							exact
							path="/">
							<Outlet />
						</Route>
						<Route path="/login">
							<Login />
						</Route>
						<Route path="/order/:shortId">
							<OrderDetail />
						</Route>
						<Route path="/order">
							<Order />
						</Route>
						<Route path="/history">
							<History />
						</Route>
						<Route path="/cancel">
							<CancelOrder />
						</Route>
						<Route path="/menu/:brandId">
							<MenuList />
						</Route>
						<Route path="/menu">
							<Menu />
						</Route>
						<Route path="/profile">
							<Profile />
						</Route>
						<Route path="/scan">
							<ScanQR />
						</Route>
						<Route path="/scanDesktop">
							<div />
						</Route>
						<Route path="/scheduler">
							<Scheduler />
						</Route>
					</Switch>
				</div>
				<BottomNavigationBar renderBottomBar={renderBottomBar} />

				<Toaster />

				{!isEmpty(localStorage.getItem('loginToken')) && <QRDeviceListener />}

				<ScanModal />
				<NotificationOffModal />
			</div>
		</ErrorBoundary>
	);
}

export default App;
