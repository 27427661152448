import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {Dialog} from '@material-ui/core';

// Global
import {setScanModalOpen} from 'store/actions';

import './index.scss';

function ScanModal() {
  const dispatch = useDispatch();

  const open = useSelector((state) => state.globalModal.scanModalOpen);

  const onBackdropClick = () => {
    dispatch(setScanModalOpen(false));
  };

  return (
    <Dialog
      className="ScanModal"
      open={open}
      onBackdropClick={onBackdropClick}
      onExited={() => document.activeElement.blur()} // force focus on body so that qr listener can run
    >
      <div className="scan-qr-image-container">
        <img
          src={require('assets/images/scan-qr.jpg')}
          className="scan-qr-image"
          alt="scan-qr"
        />
      </div>
      <div className="scan-qr-description">
        Arahkan QR pelanggan ke alat pemindai
      </div>
    </Dialog>
  );
}

export default ScanModal;
