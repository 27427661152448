import Axios from 'axios';
import {get} from 'lodash';

import store from 'store';
import {setToasterData, setToasterOpen} from 'store/actions';
import {TOASTER_STATUS} from 'utils/constants';
import logger from 'utils/logger';

export const updateOutletSchedule = async (schedules) => {
  try {
    const outletId = store.getState().outlet.selectedOutletId;
    // toggle menu availability
    const {data} = await Axios.post(`${process.env.REACT_APP_HANGRY_SERVICE_API}/intools/gql`, {
      query: `mutation UpdateOutletSchedule($outletId: uuid!, $schedules: [outletSchedule]) {
        updateOutletSchedule(outletId: $outletId, schedules: $schedules) {
          success,
          message
        }
      }`,
      variables: {
        outletId,
        schedules: schedules,
      }
    }, {
      headers: {
        Authorization: localStorage.getItem('loginToken')
      }
    });

    if (get(data, 'errors[0].extensions.code') === 'UNAUTHENTICATED') return window.location.reload();
    if (data.errors) throw new Error(JSON.stringify(data.errors));

    if (data.data.updateOutletSchedule.success === 'false') throw new Error(data.data.updateOutletSchedule.message);

    // show snackbar
    store.dispatch(setToasterData({message: 'Jadwal outlet berhasil diubah', status: TOASTER_STATUS.SUCCESS}));
    store.dispatch(setToasterOpen(true));

    return true;
  } catch (error) {
    console.error(error);
    logger.notify(error);

    // show snackbar
    store.dispatch(setToasterData({message: 'Jadwal outlet gagal diubah', status: TOASTER_STATUS.FAILED}));
    store.dispatch(setToasterOpen(true));
    return false;
  }
}