import React, {useEffect, useRef} from 'react';
import {useSelector, useDispatch} from 'react-redux';

import {Snackbar} from '@material-ui/core';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheck, faTimes, faExclamation} from '@fortawesome/pro-solid-svg-icons';

import './index.scss';
import {TOASTER_STATUS} from 'utils/constants';
import {setToasterOpen} from 'store/actions';
import COLOR_PALLETES from 'assets/styles/_colors.scss';

function Toaster() {
	const timeoutRef = useRef();
	const dispatch = useDispatch();

	const toasterOpen = useSelector(state => state.toaster.open);
	const toasterMessage = useSelector(state => state.toaster.message);
	const toasterStatus = useSelector(state => state.toaster.status);

	const SNACKBAR_STATUS = {
		[TOASTER_STATUS.SUCCESS]: {color: COLOR_PALLETES.matcha, icon: faCheck},
		[TOASTER_STATUS.WARNING]: {color: COLOR_PALLETES.mentaiLightest, icon: faExclamation},
		[TOASTER_STATUS.FAILED]: {color: COLOR_PALLETES.sauceLight, icon: faExclamation},
	};

	useEffect(() => {
		clearTimeout(timeoutRef.current);
		if (toasterOpen) {
			timeoutRef.current = setTimeout(() => {
				dispatch(setToasterOpen(false));
			}, 5000);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [toasterOpen, toasterMessage, toasterStatus]);

	return (
		<Snackbar
			className='snackbar'
			open={toasterOpen}
			onClose={() => dispatch(setToasterOpen(false))}
		>
			<div className='snackbar-container row'>
				<div
					className='icon-left row'
					style={{backgroundColor: SNACKBAR_STATUS[toasterStatus].color}}>
					<FontAwesomeIcon
						icon={SNACKBAR_STATUS[toasterStatus].icon}
						className='icon' />
				</div>
				<div
					className='snackbar-message'
					style={{color: SNACKBAR_STATUS[toasterStatus].color}}>{toasterMessage}</div>
				<div
					className='icon-right row'
					onClick={() => dispatch(setToasterOpen(false))}>
					<FontAwesomeIcon
						icon={faTimes}
						className='icon' />
				</div>
			</div>
		</Snackbar>
	);
}

export default Toaster;