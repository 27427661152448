import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

import {version} from '../../package.json';

import store from 'store';
import get from 'lodash/get';

const setBugsnagObjectMetadata = (report, errObj) => {
	Object.keys(errObj).forEach(key => {
		report.addMetadata('metadata', key, errObj[key]);
	});
};

Bugsnag.start({
	apiKey: '7d7c20a3834a6f9a54e2eba2b3384d13',
	autoBreadcrumbs: false,
	onError: event => {
		const loginToken = localStorage.getItem('loginToken');
		if (loginToken) {
			const selectedOutletIndex = get(
				store.getState(),
				'outlet.selectedOutlet',
			);
			const outlet = get(
				store.getState(),
				`outlet.availableOutlets[${selectedOutletIndex}]`,
				{},
			);
			event.addMetadata('outlet', {
				outletId: outlet.id,
				outletName: outlet.name,
			});
		}
	},
	plugins: [new BugsnagPluginReact()],
	releaseStage: process.env.REACT_APP_BUGSNAG_RELEASE_STAGE,
	appVersion: version,
});
console.log('Bugsnag initialized!');

const notify = err => {
	Bugsnag.notify(err, report => {
		if (get(err, 'response.config')) {
			const {headers, method, params, data, url} = err.response.config;
			setBugsnagObjectMetadata(report, {
				headers: JSON.stringify(headers),
				params: JSON.stringify(params),
				requestData: JSON.stringify(data),
				responseData: JSON.stringify(err.response.data),
				status: err.response.status,
				method: method,
				url: url,
			});
		} else if (err.graphql) {
			setBugsnagObjectMetadata(report, err.graphql);
		} else {
			report.addMetadata('metadata', 'json', err);
		}
	});
};

export default {notify};
