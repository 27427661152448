import {SET_UNAVAILABLE_MENU_COUNT} from '../actionTypes';

const initialState = {
  unavailableMenuCount: 0,
};

const scanModal = (state = initialState, action) => {
  switch (action.type) {
    case SET_UNAVAILABLE_MENU_COUNT:
      return {...state, unavailableMenuCount: action.count};
    default:
      return state;
  }
}

export default scanModal;