import React from 'react';

import './index.scss';


function EmptyMenus({isUnavailableMode, isSearchMode}) {
  let title;
  let subtitle;
  if (isSearchMode) {
    title = 'Menu tidak ditemukan';
    subtitle = 'Menu yang dicari tidak ditemukan';
  } else if (isUnavailableMode) {
    title = 'Belum ada stok yang habis';
    subtitle = 'Stok menu yang habis akan tampil disini';
  } else {
    title = 'Belum ada menu';
    subtitle = 'Belum ada menu yang tersedia pada bagian ini';
  }
  return (
    <div className='EmptyMenus column'>
      <img src={require('assets/images/empty.svg')} alt='empty' className='empty-menu-image' />
      <div className='empty-title'>{title}</div>
      <div className='empty-description'>{subtitle}</div>
    </div>
  )
}

export default EmptyMenus;