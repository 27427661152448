export const TIME_LIMIT = 5; //in Minutes

export const ORDER_FILTER = {
	PENDING_ORDER: 'pending',
	DELIVERY_PROCESS: 'delivery',
	TAKEAWAY_PROCESS: 'takeaway',
};

export const TOASTER_STATUS = {
	SUCCESS: 'SUCCESS',
	WARNING: 'WARNING',
	FAILED: 'FAILED',
};

export const DAYS_NAME = {
	mon: 'Senin',
	tue: 'Selasa',
	wed: 'Rabu',
	thu: 'Kamis',
	fri: 'Jumat',
	sat: 'Sabtu',
	sun: 'Minggu',
};

export const LOGIN_ERRORS = {
	WRONG_EMAIL_OR_PASSWORD: 'email and password do not match!',
	ACCOUNT_LOCKED: 'account is locked!',
};

export const ORDER_STATUS = {
	PAID: 'paid',
	PROCESSED: 'processed',
	FIND_DRIVER: 'find_driver',
	DRIVER_FOUND: 'driver_found',
	REFIND_DRIVER: 'refind_driver',
	PICKUP_READY: 'pickup_ready',
	PICKED_UP: 'picked_up',
	DELIVERY: 'delivery',
	DELIVERED: 'delivered',
	CANCELLED: 'cancelled',
	REFUND: 'refund',
};