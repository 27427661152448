import React, {useState} from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExclamationTriangle, faSpinner, faUtensils} from '@fortawesome/pro-solid-svg-icons';

// Redux
import store from 'store';
import {setToasterData, setToasterOpen} from 'store/actions';

// Global components
import Button from 'components/Button';

// Global utils
import {TIME_LIMIT, TOASTER_STATUS} from 'utils/constants';
import {numberToRupiah} from 'utils/general';
import {getDriverInfo} from 'utils/utils';

// Global assets
import COLOR_PALLETES from 'assets/styles/_colors.scss';

// Local
import './index.scss';

function OrderEntry({data, isRobotOnline, isHistory, isPendingOrder, isProcessingOrder, onClick, onClickManualProcess, onClickAutomaticProcess}) {
	const [autoProcessLoading, setAutoProcessLoading] = useState(false);

	const {driverName, driverPhone} = getDriverInfo(data);

	const elapsedMinutes =
		moment().subtract(data.paidAt, 'milliseconds').unix() / 60;

	const showAutoProcessError = !isHistory && data.require_manual_process !== false && data.errorCode;
	// const showReadyToPickUp = !isHistory && data.status === 'pickup_ready';
	const showReadyToPickUp = false;
	// const numberOfItems = data?.items ? JSON.parse(data.items).length : '?';
	const numberOfItems = data?.items?.length;
	const isTakeaway = data.type === 'takeaway';
	const isDriverEmpty =
    (isEmpty(driverName) && isEmpty(driverPhone)) ||
    (driverName === 'Default' && driverPhone === 'Default');

	let errorMessage;
	switch (data.errorCode) {
	case 'ERR-001': errorMessage = 'Menu tidak tersedia di sistem. Laporkan'; break;
	case 'ERR-002': errorMessage = 'Terjadi kendala pada sistem. Laporkan'; break;
	default: errorMessage = '';
	}

	return (
		<div
			className={`
				OrderEntry 
				${(showAutoProcessError || showReadyToPickUp) && 'no-top-padding'}
				${!isPendingOrder && 'touchable-opacity'}
			`}
			onClick={() => onClick()}>
			{showAutoProcessError && (
				<div className="alert-container row mentai">
					<div className="icon-container">
						<FontAwesomeIcon
							icon={faExclamationTriangle}
							className="alert-icon"
						/>
					</div>
					{errorMessage}
				</div>
			)}
			{/* {showReadyToPickUp && (
				<div className="alert-container row matcha">
					<div className="icon-container">
						<FontAwesomeIcon
							icon={faUtensils}
							className="alert-icon" />
					</div>
					Pesanan Siap Diambil
				</div>
			)} */}
			<div className="order-type-container">
				<span className="order-type-text">
					{isTakeaway ? 'Takeaway' : 'Delivery'}
				</span>
				{' · '}
				{data.shortId}
			</div>
			<div className="order-time-queue-container">
				<div
					className="order-time"
					style={{
						color:
								!isHistory && elapsedMinutes > TIME_LIMIT
									? COLOR_PALLETES.sauceLight
									: COLOR_PALLETES.blackLight,
					}}>
					{isHistory
						? data.status === 'cancelled'
							? 'Dibatalkan'
							: 'Selesai'
						: moment(data.paidAt).fromNow()}
				</div>
				{/* <div className="queue-number">{data.queueNo}</div> */}
			</div>
			<div className="order-details-text">
				Total: {numberOfItems} Pesanan ({numberToRupiah(data.amount)})
			</div>
			<div className="order-details-text">
				{isTakeaway ? 'Diambil: ' : 'Driver: '}
				{isTakeaway
					? data.userName
					: data.status === 'find_driver'
						? 'Sedang dicari'
						: data.status === 'refind_driver'
							? 'Sedang dicari driver baru'
							: isDriverEmpty ? 'Akan dicari' : driverName}
			</div>
			{/* {isPendingOrder && (
				<div className="pending-buttons row">
					<Button
						size='sm'
						type='secondary'
						color='red'
						text="Proses Manual"
						onClick={onClickManualProcess}
						disabled={isProcessingOrder || autoProcessLoading}
					/>
					<Button
						size='sm'
						type='primary'
						color='red'
						text={
							isProcessingOrder || autoProcessLoading ? (
								<span>
									<FontAwesomeIcon
										icon={faSpinner}
										className="spinner-icon"
										spin
									/>
									&nbsp;
									Memproses
								</span>
							) : 'Proses Otomatis'
						}
						onClick={() => {
							if (isRobotOnline) {
								setAutoProcessLoading(true);
								onClickAutomaticProcess();
								setTimeout(() => setAutoProcessLoading(false), 2000);
							} else {
								store.dispatch(setToasterData({message: 'Mohon untuk menyalakan robot kasir terlebih dahulu', status: TOASTER_STATUS.FAILED}));
								store.dispatch(setToasterOpen(true));
							}
						}}
						disabled={isProcessingOrder || autoProcessLoading}
					/>
				</div>
			)} */}
		</div>
	);
}

OrderEntry.defaultProps = {
	data: {},
	isRobotOnline: false,
	isHistory: false,
	isPendingOrder: false,
	isProcessingOrder: false,
	onClick: () => null,
	onClickManualProcess: () => null,
	onClickAutomaticProcess: () => null,
};

OrderEntry.propTypes = {
	data: PropTypes.object,
	isRobotOnline: PropTypes.bool,
	isHistory: PropTypes.bool,
	isPendingOrder: PropTypes.bool,
	isProcessingOrder: PropTypes.bool,
	onClick: PropTypes.func,
	onClickManualProcess: PropTypes.func,
	onClickAutomaticProcess: PropTypes.func,
};

export default OrderEntry;