import React from 'react';
import PropTypes from 'prop-types';
import HashLoader from 'react-spinners/HashLoader';

import {Switch} from '@material-ui/core';

import COLOR_PALLETES from 'assets/styles/_colors.scss';

import './index.scss';

function SwitchButton({checked, onClick, loading, style}) {
  return (
    <div className='SwitchButton' style={style}>
      <Switch
        checked={checked}
        onClick={() => {
          document.activeElement.blur();
          onClick();
        }}
      />
      <div className='switch-loading-indicator' style={checked ? {top: 4, right: 4} : {top: 4, left: 4}}>
        <HashLoader loading={loading} size={16} color={COLOR_PALLETES.grey} />
      </div>
    </div>
  )
}

SwitchButton.defaultProps = {
  checked: false,
  loading: false,
  onClick: () => null,
}

SwitchButton.propTypes = {
  checked: PropTypes.bool,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  style: PropTypes.object,
};

export default SwitchButton;
