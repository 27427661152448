import React from 'react';
import {InputAdornment, TextField} from '@material-ui/core';
import {Search} from '@material-ui/icons';

import './index.scss';


function SearchInput({value, onChange}) {

	return (
		<div className='SearchInput'>
			<TextField
				value={value}
				onChange={e => onChange(e.target.value)}
				className='input'
				variant='outlined'
				placeholder='Cari menu'
				type='search'
				InputProps={{
					startAdornment: (
						<InputAdornment position="start">
							<Search />
						</InputAdornment>
					),
				}} />
		</div>
	);
}

export default SearchInput;