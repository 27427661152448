import {combineReducers} from 'redux';

import auth from './auth.js';
import outlet from './outlet.js';
import toaster from './toaster.js';
import globalModal from './globalModal.js';
import menu from './menu.js';

export default combineReducers({
  auth,
  outlet,
  toaster,
  globalModal,
  menu,
});
