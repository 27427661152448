import {SET_USER_DATA, SET_FIREBASE_TOKEN, SET_FIREBASE_MESSAGING_TOKEN} from '../actionTypes';

const initialState = {
  user: null,
  firebaseToken: null,
  firebaseMessagingToken: null,
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_DATA:
      return {...state, user: action.payload};
    case SET_FIREBASE_TOKEN:
      return {...state, firebaseToken: action.token};
    case SET_FIREBASE_MESSAGING_TOKEN:
      return {...state, firebaseMessagingToken: action.token};
    default:
      return state;
  }
}

export default user;