import React, {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {useSelector} from 'react-redux';
import Axios from 'axios';
import {get} from 'lodash';

import {Container} from '@material-ui/core';

import './index.scss';

import logger from 'utils/logger';

import Header from 'components/Header';
import BrandList from './components/BrandList';
import OutOfStockCard from 'components/OutOfStockCard';
import FullScreenLoading from 'components/FullScreenLoading';

function Menu() {
	const history = useHistory();

	const outletId = useSelector(state => state.outlet.selectedOutletData.id);

	const [brands, setBrands] = useState([]);

	const getBrands = async () => {
		try {
			const {data} = await Axios.post(
				`${process.env.REACT_APP_HANGRY_SERVICE_API}/intools/gql`,
				{
					query: `query Brands($outletId: String) {
          brands(filter: {outletId: $outletId}) {
            count
            rows {
              id,
              name,
              logo,
              sequence
            }
          }
        }`,
					variables: {
						outletId,
					},
				},
				{
					headers: {
						Authorization: localStorage.getItem('loginToken'),
					},
				},
			);

			if (get(data, 'errors[0].extensions.code') === 'UNAUTHENTICATED')
				return window.location.reload();
			if (data.errors) throw new Error(JSON.stringify(data.errors));

			setBrands(data.data.brands.rows.sort((a, b) => a.sequence - b.sequence));
		} catch (error) {
			console.error(error);
			logger.notify(error);
		}
	};

	useEffect(() => {
		if (outletId) getBrands();
		// eslint-disable-next-line
  }, [outletId]);

	return (
		<div className="Menu">
			<Header title="Atur Menu" />
			{brands.length > 0 ? (
				<Container
					maxWidth="md"
					className="container">
					<OutOfStockCard />
					<div className="brand-title">Daftar Brand</div>
					<div className="brand-container">
						{brands.map(({name, logo, id}, i) => (
							<div
								className="brand"
								key={i}>
								<BrandList
									src={logo}
									title={name}
									onClick={() =>
										history.push(`/menu/${id}`, {
											brandName: name,
										})
									}
								/>
							</div>
						))}
					</div>
				</Container>
			) : (
				<FullScreenLoading />
			)}
		</div>
	);
}

export default Menu;
