import React, {useState} from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faDoorClosed, faDoorOpen} from '@fortawesome/pro-solid-svg-icons';

import {useInterval} from 'utils/interval';
import COLOR_PALLETES from 'assets/styles/_colors.scss';
import SwitchButton from 'components/SwitchButton';
import ActionModal from 'components/ActionModal';

import './index.scss';
import {getIndonesianTimezoneLabel} from 'utils/utils';


function OutletOpenCloseDetail({isOpen, isOutletOperationalHour, operationalHour, isLoadingToggleOutlet, onToggleOutlet, timezone}) {

	const [, forceUpdate] = React.useState(0);
	const [closeModalOpen, setCloseModalOpen] = useState(false);

	const operationalHourSplitted = operationalHour.replace(' ', '').split('-');
	const openHour = moment.tz(operationalHourSplitted[0], 'HH:mm', timezone);
	const isCloseToOpen = !isOutletOperationalHour && moment().isBetween(openHour.clone().subtract(16, 'minute'), openHour);

	let displayData;
	if (isOutletOperationalHour) {
		if (isOpen) {
			displayData = {
				icon: faDoorOpen,
				iconColor: COLOR_PALLETES.matcha,
				iconContainerColor: COLOR_PALLETES.matchaLightest,
				titleColor: COLOR_PALLETES.matcha,
				title: 'Sedang buka',
			};
		} else {
			displayData = {
				icon: faDoorClosed,
				iconColor: COLOR_PALLETES.blackLight,
				iconContainerColor: COLOR_PALLETES.greyLighter,
				titleColor: COLOR_PALLETES.sauceLight,
				title: 'Sedang tutup',
			};
		}
		displayData.description = 'Outlet akan tutup sesuai jadwal reguler atau geser tombol untuk tutup manual';
	} else if (isCloseToOpen) {
		const timeRemaining = moment().to(openHour);
		displayData = {
			icon: faDoorClosed,
			iconColor: COLOR_PALLETES.mentaiDark,
			iconContainerColor: COLOR_PALLETES.mentaiLightest,
			titleColor: COLOR_PALLETES.mentaiDark,
			title: 'Buka otomatis ' + timeRemaining,
			description: 'Outlet akan buka otomatis sesuai jadwal reguler dan tidak dapat dibuka secara manual',
		};
	} else {
		displayData = {
			icon: faDoorClosed,
			iconColor: COLOR_PALLETES.blackLight,
			iconContainerColor: COLOR_PALLETES.greyLighter,
			titleColor: COLOR_PALLETES.sauceLight,
			title: 'Sedang tutup',
			description: 'Outlet akan buka otomatis sesuai jadwal reguler dan tidak dapat dibuka secara manual',
		};
	}

	useInterval(() => {
		forceUpdate(n => !n);
	}, 60000);

	return (
		<div className='OutletOpenCloseDetail row'>
			<div
				className='icon-container'
				style={{backgroundColor: displayData.iconContainerColor}}>
				<FontAwesomeIcon
					className='icon'
					icon={displayData.icon}
					color={displayData.iconColor} />
			</div>
			<div className='detail-container'>
				<div
					className='detail-title'
					style={{color: displayData.titleColor}}>{displayData.title}</div>
				<div className='detail-description'>{displayData.description}</div>
			</div>

			{isOutletOperationalHour && (
				<SwitchButton
					checked={isOpen}
					onClick={() => isOpen ? setCloseModalOpen(true) : onToggleOutlet()}
					loading={isLoadingToggleOutlet}
				/>
			)}

			<ActionModal
				open={closeModalOpen}
				title='Tutup lebih awal?'
				description={`Outlet akan tutup otomatis pada pukul ${operationalHourSplitted[1]} ${getIndonesianTimezoneLabel(timezone)}. Kamu yakin ingin menutupnya lebih awal dari jadwal reguler?`}
				leftButtonText='Batal'
				rightButtonText='Tutup'
				onBackdropClick={() => setCloseModalOpen(false)}
				onClickLeft={() => setCloseModalOpen(false)}
				onClickRight={() => {
					setCloseModalOpen(false);
					onToggleOutlet();
				}}
			/>
		</div>
	);
}

OutletOpenCloseDetail.defaultProps = {
	isOpen: false,
	isOutletOperationalHour: false,
	operationalHour: '00:00-23:59',
	timezone: 'Asia/Jakarta',
	isLoadingToggleOutlet: false,
	onToggleOutlet: () => null,
};

OutletOpenCloseDetail.propTypes = {
	isOpen: PropTypes.bool,
	isOutletOperationalHour: PropTypes.bool,
	operationalHour: PropTypes.string,
	timezone: PropTypes.string,
	isLoadingToggleOutlet: PropTypes.bool,
	onToggleOutlet: PropTypes.func,
};

export default OutletOpenCloseDetail;