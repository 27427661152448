import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import Axios from 'axios';
import {get} from 'lodash';

import {
  Container,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
} from '@material-ui/core';

import Header from 'components/Header';
import Button from 'components/Button';

import {setToasterData, setToasterOpen} from 'store/actions';
import COLOR_PALLETES from 'assets/styles/_colors.scss';
import {TOASTER_STATUS} from 'utils/constants';
import logger from 'utils/logger';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronLeft, faCheckCircle} from '@fortawesome/pro-solid-svg-icons';
import {faCircle} from '@fortawesome/pro-light-svg-icons';

import './index.scss';
import ActionModal from 'components/ActionModal';

const CANCEL_REASONS = [
  {value: 'out_of_stock', text: 'Stok yang dipesan pelanggan habis'},
  {value: 'customer_request', text: 'Pelanggan meminta untuk dibatalkan'},
  {value: 'outlet_closed', text: 'Outlet lagi tutup'},
  {value: 'others', text: 'Alasan lainnya'},
];

function CancelOrder(props) {
  const history = useHistory();
  const dispatch = useDispatch();

  const {orderId, outletId} = history.location.state || {};

  const [isCancellingOrder, setIsCancellingOrder] = useState(false);
  const [cancelReason, setCancelReason] = useState(CANCEL_REASONS[0].value);
  const [cancelOtherReason, setCancelOtherReason] = useState('');
  const [cancelConfirmation, setCancelConfirmation] = useState(false);

  const cancelOrder = async () => {
    try {
      setIsCancellingOrder(true);
      const {data} = await Axios.post(
        `${process.env.REACT_APP_HANGRY_SERVICE_API}/intools/gql`,
        {
          query: `mutation UpdateOrderStatus($orderId: uuid!, $outletId: uuid!, $status: String!, $notes: String!) {
          updateOrderStatus(orderId: $orderId, outletId: $outletId, status: $status, notes: $notes) {
            success,
            message
          }
        }`,
          variables: {
            orderId,
            outletId,
            status: 'cancelled',
            notes:
              cancelReason +
              (cancelReason === 'others'
                ? `: ${JSON.stringify(cancelOtherReason)}`
                : ''),
          },
        },
        {
          headers: {
            Authorization: localStorage.getItem('loginToken'),
          },
        },
      );

      if (get(data, 'errors[0].extensions.code') === 'UNAUTHENTICATED')
        return window.location.reload();
      if (data.errors) throw new Error(JSON.stringify(data.errors));

      if (data.data.updateOrderStatus.success === 'false')
        throw new Error(data.data.updateOrderStatus.message);

      // show snackbar
      dispatch(
        setToasterData({
          message: 'Pesanan berhasil dibatalkan',
          status: TOASTER_STATUS.SUCCESS,
        }),
      );
      dispatch(setToasterOpen(true));

      // if succeed redirect to history, let it delay 1000 to wait for BE process.
      setTimeout(() => {
        history.goBack();
      }, 1000);
    } catch (error) {
      logger.notify(error);
      // show snackbar
      dispatch(
        setToasterData({
          message: 'Pesanan gagal dibatalkan',
          status: TOASTER_STATUS.FAILED,
        }),
      );
      dispatch(setToasterOpen(true));

      setIsCancellingOrder(false);
      setCancelConfirmation(false);
    }
  };

  const StyledRadio = (props) => {
    return (
      <Radio
        className="radio-icon-container"
        checkedIcon={
          <FontAwesomeIcon
            className="radio-icon"
            icon={faCheckCircle}
            color={COLOR_PALLETES.matcha}
          />
        }
        icon={
          <FontAwesomeIcon
            className="radio-icon"
            icon={faCircle}
            color={COLOR_PALLETES.greyLight}
          />
        }
        {...props}
      />
    );
  };

  const isButtonDisabled =
    cancelReason === 'others' && cancelOtherReason.length === 0;

  return (
    <div className="CancelOrder">
      <Header
        title="Konfirmasi Pembatalan"
        faLeftIcon={faChevronLeft}
        renderBottomBorder
        onClickLeftIcon={() => history.goBack()}
      />
      <Container maxWidth="md" className="container">
        <div className="top-container">
          <div className="cancel-title">Pilih alasan pembatalan</div>
          <div className="cancel-description">Wajib pilih satu*</div>

          <FormControl component="fieldset" className="cancel-radio-container">
            <RadioGroup
              value={cancelReason}
              onClick={() => document.activeElement.blur()}
              onChange={({target}) => setCancelReason(target.value)}
              aria-label="cancel-reason"
              name="cancel-radios">
              {CANCEL_REASONS.map((reason, i) => (
                <FormControlLabel
                  key={i}
                  value={reason.value}
                  control={<StyledRadio />}
                  label={reason.text}
                />
              ))}
            </RadioGroup>
          </FormControl>

          {cancelReason === 'others' && (
            <React.Fragment>
              <div className="cancel-other-reason-header">Apa alasannya?</div>
              <TextField
                className="cancel-other-reason"
                multiline
                placeholder="Tulis alasan lebih detail disini"
                variant="outlined"
                rows={4}
                value={cancelOtherReason}
                onChange={({target}) => setCancelOtherReason(target.value)}
              />
            </React.Fragment>
          )}
        </div>
        <div className="cancel-button-container">
          <Button
            type="primary"
            size="lg"
            color="red"
            text="Batalkan Pesanan"
            disabled={isButtonDisabled}
            className="cancel-button"
            onClick={() => setCancelConfirmation(true)}
          />
        </div>
      </Container>
      <ActionModal
        open={cancelConfirmation}
        loading={isCancellingOrder}
        title="Yakin ingin membatalkan pesanan?"
        description={`Alasan pembatalan: ${
          cancelReason === 'others'
            ? cancelOtherReason
            : CANCEL_REASONS.find((e) => e.value === cancelReason)?.text
        }`}
        leftButtonText="Tidak"
        rightButtonText="Iya"
        onBackdropClick={() => setCancelConfirmation(false)}
        onClickLeft={() => setCancelConfirmation(false)}
        onClickRight={cancelOrder}
      />
    </div>
  );
}

export default CancelOrder;
