import jwt_decode from 'jwt-decode';
import Axios from 'axios';
import get from 'lodash/get';
import moment from 'moment-timezone';

import firebaseUtils from 'config/firebase';

import {ORDER_STATUS, TOASTER_STATUS} from './constants';
import {fetchSelectedOutletData, subscribeOutlet} from 'utils/request/outlet';
import {history} from 'utils/history';
import logger from 'utils/logger';

import store from 'store';
import {
	setUserData,
	setFirebaseToken,
	setAvailableOutlets,
	setFirebaseMessagingToken,
	setSelectedOutletId,
	setToasterData,
	setToasterOpen,
	setNotificationOffModalOpen,
} from 'store/actions';

export const initializeApp = async loginToken => {
	// Decode jwt token and set to redux
	const decoded = jwt_decode(loginToken);

	store.dispatch(
		setUserData({
			id: decoded.session.user.id,
			username: decoded.session.user.username,
			roles: decoded.session.user.scopes.roles,
		}),
	);
	store.dispatch(setFirebaseToken(decoded.session.firebase_token));
	store.dispatch(
		setSelectedOutletId(decoded.session.user.scopes.outlets[0].id),
	);
	store.dispatch(setAvailableOutlets(decoded.session.user.scopes.outlets));

	const firebaseMessagingToken = await firebaseUtils.getFirebaseMessagingToken();
	store.dispatch(setFirebaseMessagingToken(firebaseMessagingToken));
	// console.log('Firebase Notification Token: ', firebaseMessagingToken);

	subscribeOutlet(0);
	fetchSelectedOutletData();
	checkNotificationPermission();
};

export const checkToken = async () => {
	try {
		const {data} = await Axios.post(
			`${process.env.REACT_APP_HANGRY_SERVICE_API}/intools/gql`,
			{
				query: 'query checkToken{checkToken}',
				operationName: 'checkToken',
			},
			{
				headers: {
					Authorization: localStorage.getItem('loginToken'),
				},
			},
		);
		if (data.data.checkToken === 'OK') return true;

		if (data.errors[0].extensions.code === 'UNAUTHENTICATED') {
			localStorage.clear();
			firebaseUtils.signOutAuthToken();
			history.push('/login');
			store.dispatch(
				setToasterData({
					message: 'Sesi telah habis, mohon untuk login kembali',
					status: TOASTER_STATUS.FAILED,
				}),
			);
			store.dispatch(setToasterOpen(true));
		} else {
			return true;
		}
	} catch (error) {
		console.error(error);
		return false;
	}
};

export const checkNotificationPermission = () => {
	const selectedOutletIndex = get(store.getState(), 'outlet.selectedOutlet');
	const outlet = get(
		store.getState(),
		`outlet.availableOutlets[${selectedOutletIndex}]`,
		{},
	);
	if ('Notification' in window) {
		if (Notification.permission !== 'granted') {
			logger.notify(
				`Outlet ${outlet.name} ${outlet.id} doesn't allow notification`,
			);
			store.dispatch(setNotificationOffModalOpen(true));
		}
	}
};

export const getIndonesianTimezoneLabel = value => {
	// 0 is a valid value and are not replaced by default value
	let utcOffset = value || value === 0 ? value : moment().utcOffset();
	if (typeof value === 'string') {
		utcOffset = moment.tz(value).utcOffset();
	}
	const utcOffsetInHour = utcOffset / 60;
	switch (utcOffsetInHour) {
	case 7:
		return 'WIB';
	case 8:
		return 'WITA';
	case 9:
		return 'WIT';
	default:
		return typeof value === 'string'
			? value
			: `UTC${utcOffsetInHour >= 0 ? '+' : '-'}${utcOffsetInHour}`;
	}
};

export const getDriverInfo = orderData => {
	return {
		driverName: get(orderData, ['deliveries', 'driver', 'driverName']) || null,
		driverPhone: get(orderData, ['deliveries', 'driver', 'driverPhone']) || null,
	};
};

export const checkIfOrderIsHistory = orderData => {
	if ([
		ORDER_STATUS.PICKED_UP,
		ORDER_STATUS.DELIVERY,
		ORDER_STATUS.DELIVERED,
		ORDER_STATUS.CANCELLED,
		ORDER_STATUS.REFUND,
	].includes(orderData?.status)) return true;
	return false;
};