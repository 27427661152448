import {SET_TOASTER_OPEN, SET_TOASTER_DATA} from '../actionTypes';

const initialState = {
  open: false,
  message: '',
  status: 'SUCCESS',
};

const toaster = (state = initialState, action) => {
  switch (action.type) {
    case SET_TOASTER_OPEN:
      return {...state, open: action.open};
    case SET_TOASTER_DATA:
      return {...state, message: action.data.message, status: action.data.status};
    default:
      return state;
  }
}

export default toaster;