import {
  SET_SCAN_MODAL_OPEN,
  SET_NOTIFICATION_OFF_MODAL_OPEN,
} from '../actionTypes';

const initialState = {
  scanModalOpen: false,
  notificationOffModalOpen: false,
};

const globalModal = (state = initialState, action) => {
  switch (action.type) {
    case SET_SCAN_MODAL_OPEN:
      return {...state, scanModalOpen: action.open};
    case SET_NOTIFICATION_OFF_MODAL_OPEN:
      return {...state, notificationOffModalOpen: action.open};
    default:
      return state;
  }
};

export default globalModal;
