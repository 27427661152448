import React from 'react';
import HashLoader from 'react-spinners/HashLoader';

import COLOR_PALLETES from 'assets/styles/_colors.scss';

import './index.scss'

function FullScreenLoading() {
  return (
    <div className='FullScreenLoading row'>
      <HashLoader color={COLOR_PALLETES.sauce} size={80} />
    </div>
  )
}

export default FullScreenLoading;