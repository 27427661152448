import React, {forwardRef, useImperativeHandle, useState} from 'react';
import {countBy, flatMap, flatMapDeep, get, groupBy, isEmpty, isEqual, uniqBy, uniqWith} from 'lodash';

import {faChevronLeft, faExclamationCircle} from '@fortawesome/pro-solid-svg-icons';

import Header from 'components/Header';
import FullScreenModal from 'components/FullScreenModal';
import FullScreenLoading from 'components/FullScreenLoading';
import Button from 'components/Button';

import {getMenuPackages} from 'utils/request/menu';

import './index.scss';
import MenuItem from '../MenuItem';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';


const PackageMenuOpenerModal = forwardRef(({open, activePackageData, onToggleMenu, toggleMenuId, toggleMenuLoading, closeDialog}, ref) => {

  const [uniqueMenuCount, setUniqueMenuCount] = useState(0);
  const [packageData, setPackageData] = useState({});
  const [packageDataLoading, setPackageDataLoading] = useState(false);

  useImperativeHandle(
    ref,
    () => ({
      fetchPackageData() {
        fetchPackageData();
      }
    }),
  )

  const fetchPackageData = async () => {
    setPackageDataLoading(true);
    const result = await getMenuPackages(activePackageData.id);
    const uniqueData = uniqWith(result, (a, b) => isEqual(a.menus, b.menus));
    const groupedData = groupBy(uniqueData, x => x.menus.length === 1 ? 'fixed' : 'options');
    const uniqueMenuLength = uniqBy(flatMapDeep(uniqueData, x => x.menus), 'id').length;
    setUniqueMenuCount(uniqueMenuLength);
    setPackageData(groupedData);
    setPackageDataLoading(false);
  }

  const clearStateToInitial = () => {
    setUniqueMenuCount(0);
    setPackageData({});
  }

  const renderFixedMenus = () => {
    const menus = flatMap(packageData.fixed, 'menus');
    const shouldShowWarning = menus.some(x => !x.isAvailable);
    return menus.length > 0 && (
      <div className='menus-container'>
        <div className='menu-header row'>
          <div className='menu-header-detail'>
            <div className='menus-title'>Menu Tetap</div>
            <div className='menus-description'>Semua menu harus tersedia</div>
          </div>
          {shouldShowWarning && <FontAwesomeIcon icon={faExclamationCircle} className='warning-icon' />}
        </div>
        {menus.map((menu, i) => (
          <MenuItem
            key={i}
            menuName={menu.label}
            price={menu.price}
            isAvailable={menu.isAvailable}
            isLoading={toggleMenuId === menu.id && (toggleMenuLoading || packageDataLoading)}
            onClickToggle={() => onToggleMenu(menu, 'mainMenu')}
          />
        ))}
      </div>
    )
  }

  const renderOptionsMenus = () => {
    return packageData.options?.map((option, i) => {
      const shouldShowWarning = get(countBy(option.menus, 'isAvailable'), 'true', 0) < 1;
      return (
        <div key={i} className='menus-container'>
          <div className='menu-header row'>
            <div className='menu-header-detail'>
              <div className='menus-title'>Menu Pilihan: {option.label}</div>
              <div className='menus-description'>Minimal 1 menu harus tersedia</div>
            </div>
            {shouldShowWarning && <FontAwesomeIcon icon={faExclamationCircle} className='warning-icon' />}
          </div>
          {option.menus.map((menu, j) => (
            <MenuItem
              key={j}
              menuName={menu.label}
              price={menu.price}
              isAvailable={menu.isAvailable}
              isLoading={toggleMenuId === menu.id && (toggleMenuLoading || packageDataLoading)}
              onClickToggle={() => onToggleMenu(menu, 'mainMenu')}
            />
          ))}
        </div>
      )
    })
  }

  const isAllowedToTurnOn = () => {
    // If one of the fixed menu is unavailable, then it's blocked from turning on package menu
    const isSomeFixedUnavailable = packageData.fixed && packageData.fixed.some(x => !x.menus[0].isAvailable);
    // Every menus in options must have minimum 1 selected to be allowed to turn on package menu
    const isSomeOptionHaveZeroSelected = packageData.options && packageData.options.some(x => get(countBy(x.menus, 'isAvailable'), 'true', 0) < 1);
    return !isSomeFixedUnavailable && !isSomeOptionHaveZeroSelected;
  }

  return (
    <FullScreenModal
      open={open}
      onEntered={fetchPackageData}
      onExited={clearStateToInitial}
      className='PackageMenuOpenerModal'
    >
      <Header
        title='Ubah Stok Paket'
        faLeftIcon={faChevronLeft}
        onClickLeftIcon={closeDialog}
        renderBottomBorder
      />
      {!isEmpty(packageData) ?
        <React.Fragment>
          <div className='package-container'>
            <div className='package-header'>
              <div className='package-title'>{activePackageData.label}</div>
              <div className='package-description'>
                Paket terhubung ke&nbsp;
            <span className='package-description-bold'>{uniqueMenuCount} menu lainnya</span>
            . Pastikan paket telah memenuhi syarat sebelum mengubah stoknya menjadi tersedia
          </div>
            </div>
            {renderFixedMenus()}
            {renderOptionsMenus()}
          </div>
          <div className='submit-button-container'>
            <Button
              type='primary'
              size='lg'
              color='red'
              text='Ubah Stok: Tersedia'
              disabled={!isAllowedToTurnOn()}
              loading={toggleMenuLoading || packageDataLoading}
              className='submit-button'
              onClick={() => onToggleMenu({...activePackageData, isPackage: false}, 'mainMenu')}
            />
          </div>
        </React.Fragment>
        : <FullScreenLoading />
      }
    </FullScreenModal>
  )
});

export default PackageMenuOpenerModal;