import Axios from 'axios';
import {get, groupBy} from 'lodash';

import store from 'store';
import {setToasterData, setToasterOpen} from 'store/actions';
import {TOASTER_STATUS} from 'utils/constants';
import logger from 'utils/logger';

export const fetchAllMenus = async (type = 'delivery') => {
	try {
		const brandId = window.location.pathname.replace('/menu/', '');
		const outletId = store.getState().outlet.selectedOutletId;

		// fetch menu data filtered by brandId and outletId
		const {data} = await Axios.post(
			`${process.env.REACT_APP_HANGRY_SERVICE_API}/intools/gql`,
			{
				query: `query FetchAllMenus($brandId: String, $outletId: String, $type: String) {
          menus(filter: {brandId: $brandId, type: $type}, limit: 0) {
            rows {
              id
              brandId
              label
              price
              isFreeItem
              isActive
              isPackage
              menuCategories {
                label
              }
              menuPerOutlet(outletId: $outletId) {
                isAvailable
              }
              type
            }
          }
          submenus(filter: {brandId: $brandId, isUpgrade: false}, limit: 0) {
            rows {
              id
              brandId
              label
              price
              description
              isActive
              submenuPerOutlet(outletId: $outletId) {
                isAvailable
              }
              type
            }
          }
        }`,
				variables: {
					brandId,
					outletId,
					type: type === 'all' ? null : type,
				},
			},
			{
				headers: {
					Authorization: localStorage.getItem('loginToken'),
				},
			},
		);

		if (get(data, 'errors[0].extensions.code') === 'UNAUTHENTICATED')
			return window.location.reload();
		if (data.errors) throw new Error(JSON.stringify(data.errors));

		const categorizedMenus = filterAndGroupMenus(data.data.menus.rows);
		const categorizedSubmenus = filterAndGroupSubmenus(data.data.submenus.rows);

		return {...categorizedMenus, ...categorizedSubmenus};
	} catch (error) {
		console.error(error);
		logger.notify(error);

		//show snackbar
		store.dispatch(
			setToasterData({
				message: 'Gagal memuat menu',
				status: TOASTER_STATUS.FAILED,
			}),
		);
		store.dispatch(setToasterOpen(true));
	}
};

export const fetchUnavailableMenus = async () => {
	try {
		const outletId = store.getState().outlet.selectedOutletId;

		// fetch menu data filtered by brandId and outletId
		const {data} = await Axios.post(
			`${process.env.REACT_APP_HANGRY_SERVICE_API}/intools/gql`,
			{
				query: `query FetchAllUnavailableMenus($outletId: String) {
          menuPerOutlet(filter: {outletId: $outletId, isAvailable: false}) {
            rows {
              isAvailable
              menu {
                id
                brandId
                label
                price
                isFreeItem
                isActive
                isPackage
                menuCategories {
                  label
                }
                type
              }
            }
          }
          submenuPerOutlet(filter: {outletId: $outletId, isAvailable: false}) {
            rows {
              isAvailable
              submenu(filter: {isUpgrade: false}) {
                id
                brandId
                label
                price
                isActive
                type
              }
            }
          }
        }`,
				variables: {
					outletId,
				},
			},
			{
				headers: {
					Authorization: localStorage.getItem('loginToken'),
				},
			},
		);

		if (get(data, 'errors[0].extensions.code') === 'UNAUTHENTICATED')
			return window.location.reload();
		if (data.errors) throw new Error(JSON.stringify(data.errors));

		const categorizedMenus = filterAndGroupMenus(
			data.data.menuPerOutlet.rows,
			true,
		);
		const categorizedSubmenus = filterAndGroupSubmenus(
			data.data.submenuPerOutlet.rows,
			true,
		);

		return {...categorizedMenus, ...categorizedSubmenus};
	} catch (error) {
		console.error(error);
		logger.notify(error);

		//show snackbar
		store.dispatch(
			setToasterData({
				message: 'Gagal memuat daftar menu habis',
				status: TOASTER_STATUS.FAILED,
			}),
		);
		store.dispatch(setToasterOpen(true));
	}
};

export const fetchToggleMenu = async (menuId, brandId, available) => {
	try {
		const outletId = store.getState().outlet.selectedOutletId;

		// toggle menu availability
		const {data} = await Axios.post(
			`${process.env.REACT_APP_HANGRY_SERVICE_API}/intools/gql`,
			{
				query: `mutation ToggleMenu($menuId: uuid!, $brandId: uuid!, $outletId: uuid!, $available: Boolean) {
          toggleMenu(menuId: $menuId, brandId: $brandId, outletId: $outletId, available: $available) {
            success,
            message
          }
        }`,
				variables: {
					menuId,
					brandId,
					outletId,
					available,
				},
			},
			{
				headers: {
					Authorization: localStorage.getItem('loginToken'),
				},
			},
		);

		if (get(data, 'errors[0].extensions.code') === 'UNAUTHENTICATED')
			return window.location.reload();
		if (data.errors) throw new Error(JSON.stringify(data.errors));

		if (data.data.toggleMenu.success === 'false')
			throw new Error(data.data.toggleMenu.message);

		// show snackbar
		store.dispatch(
			setToasterData({
				message: 'Ketersediaan menu berhasil diperbarui',
				status: TOASTER_STATUS.SUCCESS,
			}),
		);
		store.dispatch(setToasterOpen(true));
		return true;
	} catch (error) {
		console.error(error);
		logger.notify(error);
		// show snackbar
		store.dispatch(
			setToasterData({
				message: 'Ketersediaan menu gagal diperbarui',
				status: TOASTER_STATUS.FAILED,
			}),
		);
		store.dispatch(setToasterOpen(true));
		return false;
	}
};

export const fetchToggleSubmenu = async (submenuId, brandId, available) => {
	try {
		const outletId = store.getState().outlet.selectedOutletId;

		// toggle submenu availability
		const {data} = await Axios.post(
			`${process.env.REACT_APP_HANGRY_SERVICE_API}/intools/gql`,
			{
				query: `mutation ToggleSubmenu($submenuId: uuid!, $brandId: uuid!, $outletId: uuid!, $available: Boolean) {
          toggleSubmenu(submenuId: $submenuId, brandId: $brandId, outletId: $outletId, available: $available) {
            success,
            message
          }
        }`,
				variables: {
					submenuId,
					brandId,
					outletId,
					available,
				},
			},
			{
				headers: {
					Authorization: localStorage.getItem('loginToken'),
				},
			},
		);

		if (get(data, 'errors[0].extensions.code') === 'UNAUTHENTICATED')
			return window.location.reload();
		if (data.errors) throw new Error(JSON.stringify(data.errors));

		if (data.data.toggleSubmenu.success === 'false')
			throw new Error(data.data.toggleSubmenu.message);

		// show snackbar
		store.dispatch(
			setToasterData({
				message: 'Ketersediaan submenu berhasil diperbarui',
				status: TOASTER_STATUS.SUCCESS,
			}),
		);
		store.dispatch(setToasterOpen(true));
		return true;
	} catch (error) {
		console.error(error);
		logger.notify(error);
		// show snackbar
		store.dispatch(
			setToasterData({
				message: 'Ketersediaan submenu gagal diperbarui',
				status: TOASTER_STATUS.FAILED,
			}),
		);
		store.dispatch(setToasterOpen(true));
		return false;
	}
};

export const checkImpactedMenus = async menuId => {
	try {
		const outletId = store.getState().outlet.selectedOutletId;

		const {data} = await Axios.post(
			`${process.env.REACT_APP_HANGRY_SERVICE_API}/intools/gql`,
			{
				query: `query CheckImpactedMenus($menuId: uuid!, $outletId: uuid!) {
          checkImpactedMenus(menuId: $menuId, outletId: $outletId) {
            menus {
              menuName
            }
          }
        }`,
				variables: {
					menuId,
					outletId,
				},
			},
			{
				headers: {
					Authorization: localStorage.getItem('loginToken'),
				},
			},
		);

		if (get(data, 'errors[0].extensions.code') === 'UNAUTHENTICATED')
			return window.location.reload();
		if (data.errors) throw new Error(JSON.stringify(data.errors));

		return data.data.checkImpactedMenus.menus;
	} catch (error) {
		console.error(error);
		logger.notify(error);
		return [];
	}
};

export const checkLinkedMenus = async menuId => {
	try {
		const outletId = store.getState().outlet.selectedOutletId;

		const {data} = await Axios.post(
			`${process.env.REACT_APP_HANGRY_SERVICE_API}/intools/gql`,
			{
				query: `query CheckMenuLink($menuId: uuid!, $outletId: uuid!) {
          checkMenuLink(menuId: $menuId, outletId: $outletId) {
            menus {
              menuName
            }
          }
        }`,
				variables: {
					menuId,
					outletId,
				},
			},
			{
				headers: {
					Authorization: localStorage.getItem('loginToken'),
				},
			},
		);

		if (get(data, 'errors[0].extensions.code') === 'UNAUTHENTICATED')
			return window.location.reload();
		if (data.errors) throw new Error(JSON.stringify(data.errors));

		return data.data.checkMenuLink.menus;
	} catch (error) {
		console.error(error);
		logger.notify(error);
		return [];
	}
};

export const getMenuPackages = async menuId => {
	try {
		const outletId = store.getState().outlet.selectedOutletId;

		const {data} = await Axios.post(
			`${process.env.REACT_APP_HANGRY_SERVICE_API}/intools/gql`,
			{
				query: `query GetMenuPackages($menuId: String, $outletId: String) {
          menuPackages(filter: {menuId: $menuId}) {
            rows {
              menus {
                id,
                brandId,
                label,
                price,
                menuPerOutlet(outletId: $outletId) {
                  isAvailable
                }
              }
            }
          }
        }`,
				variables: {
					menuId,
					outletId,
				},
			},
			{
				headers: {
					Authorization: localStorage.getItem('loginToken'),
				},
			},
		);

		if (get(data, 'errors[0].extensions.code') === 'UNAUTHENTICATED')
			return window.location.reload();
		if (data.errors) throw new Error(JSON.stringify(data.errors));

		const formattedData = data.data.menuPackages.rows.map(packageMenu => ({
			menus: packageMenu.menus.map(menu => ({
				...menu,
				isAvailable: menu.menuPerOutlet[0].isAvailable,
			})),
		}));

		return formattedData;
	} catch (error) {
		console.error(error);
		logger.notify(error);

		//show snackbar
		store.dispatch(
			setToasterData({
				message: 'Gagal memuat menu paket',
				status: TOASTER_STATUS.FAILED,
			}),
		);
		store.dispatch(setToasterOpen(true));
	}
};

// Local utils
const filterAndGroupMenus = (data, isUnavailable) => {
	const filteredMenus = !isUnavailable
		? data.reduce((filtered, nextEntry) => {
			if (nextEntry.isActive && nextEntry.menuPerOutlet.length > 0) {
				filtered.push({
					...nextEntry,
					isAvailable: nextEntry.menuPerOutlet[0].isAvailable,
					isSubmenu: false,
				});
			}
			return filtered;
		}, [])
		: data.reduce((filtered, nextEntry) => {
			if (nextEntry.menu.isActive) {
				filtered.push({
					...nextEntry.menu,
					isAvailable: nextEntry.isAvailable,
					isSubmenu: false,
				});
			}
			return filtered;
		}, []);
	const groupedMenus = groupBy(filteredMenus, e => {
		if (e.isFreeItem) return `freeItem-${e.type}`;
		if (e.isPackage) return `packageMenu-${e.type}`;
		if (['Ala Carte', 'Extra'].includes(e.menuCategories[0]?.label))
			return `alaCarte-${e.type}`;
		return `mainMenu-${e.type}`;
	});
	delete groupedMenus.freeItem; // removes Menu Rp1 menus
	return groupedMenus;
};

const filterAndGroupSubmenus = (data, isUnavailable) => {
	const filteredSubmenus = !isUnavailable
		? data.reduce((filtered, nextEntry) => {
			if (nextEntry.isActive && nextEntry.submenuPerOutlet.length > 0) {
				filtered.push({
					...nextEntry,
					isAvailable: nextEntry.submenuPerOutlet[0].isAvailable,
					isSubmenu: true,
				});
			}
			return filtered;
		}, [])
		: data.reduce((filtered, nextEntry) => {
			if (nextEntry.submenu && nextEntry.submenu.isActive) {
				filtered.push({
					...nextEntry.submenu,
					isAvailable: nextEntry.isAvailable,
					isSubmenu: true,
				});
			}
			return filtered;
		}, []);
	const groupedSubmenus = groupBy(filteredSubmenus, e => {
		return `subMenu-${e.type}`;
	});
	return groupedSubmenus;
};
