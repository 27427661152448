import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {Dialog} from '@material-ui/core';

// Global
import {setNotificationOffModalOpen} from 'store/actions';

import './index.scss';
import {isMobile} from 'utils/general';

function NotificationOffModal() {
	const dispatch = useDispatch();

	const open = useSelector(
		state => state.globalModal.notificationOffModalOpen,
	);

	const onBackdropClick = () => {
		dispatch(setNotificationOffModalOpen(false));
	};

	const imageWidth = isMobile() ? '100%' : 300;
	let count = 0;

	return (
		<Dialog
			className="NotificationOffModal"
			open={open}
			onBackdropClick={onBackdropClick}
			onExited={() => document.activeElement.blur()} // force focus on body so that qr listener can run
		>
			<div className="content-container">
				<div className="description">
					WAJIB menyalakan notifikasi order baru
				</div>
				<div className="instructions">
					{++count}. Buka <a href="https://resto.ishangry.com">Resto App</a> di
					browser Chrome
				</div>
				<div className="instructions">{++count}. Klik tombol gembok 🔒</div>
				<img
					src={require('assets/images/1-notification.jpg')}
					className="image"
					width={imageWidth}
					alt="notification-off"
				/>
				{!isMobile() ? (
					<React.Fragment>
						<div className="instructions">
							{++count}. Pada bagian Notifications 🔔 pilih &quot;Allow&quot;
							atau &quot;Izinkan&quot;
						</div>
						<img
							src={require('assets/images/2-notification-desktop.jpg')}
							className="image"
							width={imageWidth}
							alt="notification-off"
						/>
					</React.Fragment>
				) : (
					<React.Fragment>
						<div className="instructions">
							{++count}. Klik bagian Notifications 🔔
						</div>
						<img
							src={require('assets/images/2-notification.jpg')}
							className="image"
							width={imageWidth}
							alt="notification-off"
						/>
						<div className="instructions">
							{++count}. Pastikan &quot;Show Notification&quot; atau
							&quot;Tunjukkan Notifikasi&quot; nyala
						</div>
						<img
							src={require('assets/images/3-notification.jpg')}
							className="image"
							width={imageWidth}
							alt="notification-off"
						/>
					</React.Fragment>
				)}
				<div className="instructions">
					{++count}. Kunjungi laman Akun dan test notifikasi
				</div>
				<img
					src={require('assets/images/4-notification.jpg')}
					className="image"
					width={imageWidth}
					alt="notification-off"
				/>
				<div className="instructions">
					{++count}. Jika sudah mengikuti instruksi di atas dan notifikasi tidak
					muncul atau tidak ada suara, dimohon untuk menghubungi tim IT
				</div>
			</div>
		</Dialog>
	);
}

export default NotificationOffModal;
