import Axios from 'axios';
import store from 'store';
import {get} from 'lodash';

import {setToasterData, setToasterOpen} from 'store/actions';
import logger from 'utils/logger';
import {TOASTER_STATUS} from '../constants';

export const scanPickUpQR = async QRPayload => {
	try {
		const outletId = store.getState().outlet.selectedOutletId;
		const {data} = await Axios.post(
			`${process.env.REACT_APP_HANGRY_SERVICE_API}/intools/gql`,
			{
				query: `mutation PickupOrder($outletId: uuid!, $QRPayload: String!) {
          pickupOrder(outletId: $outletId, QRPayload: $QRPayload) {
            success,
            message,
            shortId
          }
        }`,
				variables: {
					QRPayload,
					outletId,
				},
			},
			{
				headers: {
					Authorization: localStorage.getItem('loginToken'),
				},
			},
		);

		if (get(data, 'errors[0].extensions.code') === 'UNAUTHENTICATED')
			return window.location.reload();
		if (data.errors) throw new Error(JSON.stringify(data.errors));

		if (data.data.pickupOrder.success === 'false')
			throw new Error(
				data.data.pickupOrder.message + ' | payload: ' + QRPayload,
			);

		// show snackbar and navigate if success
		store.dispatch(
			setToasterData({
				message: 'Pesanan berhasil diselesaikan 🎉',
				status: TOASTER_STATUS.SUCCESS,
			}),
		);
		store.dispatch(setToasterOpen(true));
		return data.data.pickupOrder.shortId;
	} catch (error) {
		logger.notify(error);
		// show snackbar

		store.dispatch(
			setToasterData({
				message: 'Pastikan kode QR dan outlet Takeaway sesuai',
				status: TOASTER_STATUS.FAILED,
			}),
		);
		store.dispatch(setToasterOpen(true));
		return null;
	}
};
