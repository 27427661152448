import React from 'react';

import './index.scss';
import {numberToRupiah} from 'utils/general';
import SwitchButton from 'components/SwitchButton';
import COLOR_PALLETES from 'assets/styles/_colors.scss';


function MenuItem({menuName, price, isAvailable, isLoading, onClickToggle}) {
  const isUnavailableMode = window.location.pathname.includes('turnedoffmenus');
  return (
    <div className='MenuItem row'>
      <div className='menu-content'>
        <div className='title'>{menuName}</div>
        <div
          className='price-text'
          style={isUnavailableMode || isAvailable ? {} : {color: COLOR_PALLETES.sauceLight}}>
          {isUnavailableMode || isAvailable
            ? price > 0
              ? numberToRupiah(price)
              : 'Gratis'
            : 'Stok habis'
          }
        </div>
      </div>
      <SwitchButton
        checked={isAvailable}
        onClick={onClickToggle}
        loading={isLoading}
      />
    </div>
  )
}

export default MenuItem;