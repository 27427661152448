import React from 'react';
import SlidingPanel from 'react-sliding-side-panel';

import './index.scss';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheck} from '@fortawesome/pro-solid-svg-icons';

function OutletSelectorBottomSheet(props) {

  const {outletList, selectedOutlet, onSelectOutlet, selectorOpen, onClose} = props;

  return (
    <SlidingPanel
      panelClassName='outlet-selector-bottom-sheet'
      isOpen={selectorOpen}
      size={50}
      backdropClicked={onClose}
      type={'bottom'}
    >
      <div className='outlet-selector-container'>
        <div className='header-handle' />
        <div className='header'>Pilih Outlet</div>
        <div className='outlet-list'>
          {outletList.map((outlet, index) => (
            <React.Fragment key={index}>
              <div className='outlet row' onClick={() => onSelectOutlet(index)}>
                <div className='outlet-list-detail'>
                  <div className='outlet-name'>{outlet.name}</div>
                  {/* <div className='outlet-open-hours'>{true ? 'Outlet Sedang Buka' : 'Outlet Sedang Tutup'}</div> */}
                </div>
                <div className='outlet-selection'>{selectedOutlet === index && <FontAwesomeIcon icon={faCheck} />}</div>
              </div>
              <div className='outlet-list-seperator' />
            </React.Fragment>
          ))}
        </div>
        {/* <div className='outlet row'>
          <div className='outlet-list-detail'>
            <div className='outlet-name'>Bendungan Hilir</div>
            <div className='outlet-open-hours'>Outlet Sedang Buka (09:00 - 20:00)</div>
          </div>
          <div className='outlet-selection'></div>
        </div>
        <div className='outlet-list-seperator' />
        <div className='outlet row'>
          <div className='outlet-list-detail'>
            <div className='outlet-name'>Bendungan Hilir</div>
            <div className='outlet-open-hours'>Outlet Sedang Buka (09:00 - 20:00)</div>
          </div>
          <div className='outlet-selection'></div>
        </div> */}
      </div>
    </SlidingPanel>
  )
}

export default OutletSelectorBottomSheet;