import React from 'react';
import PropTypes from 'prop-types';

import {Dialog, Slide} from '@material-ui/core';

import './index.scss'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function FullScreenModal({open, onEntered, onExited, className, children}) {
  return (
    <Dialog
      fullScreen
      className={`FullScreenModal ${className}`}
      open={open}
      onEntered={onEntered}
      onExited={() => {
        document.activeElement.blur();
        onExited();
      }} // force focus on body so that qr listener can run
      TransitionComponent={Transition}
      >
      <div className='modal-container'>
        {children}
      </div>
    </Dialog >
  )
}

FullScreenModal.defaultProps = {
  open: false,

}

FullScreenModal.propTypes = {
  open: PropTypes.bool,
};

export default FullScreenModal;