import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faMotorcycle, faPhone} from '@fortawesome/pro-solid-svg-icons';

import Button from 'components/Button';

import './index.scss';
function ContactRow({icon, description, phoneNumber, title, subtitle, children, orderType, showReportDriverModal, driverMarked}) {
	const callPeople = () => {
		window.open(`tel:${phoneNumber}`);
	};

	return (
		<div className='ContactRow'>
			<div className="row">
				<div className='person-logo'><FontAwesomeIcon
					className='person-icon'
					icon={icon} /></div>
				<div className='person-detail'>
					<div className='person-header-description'>{description}</div>
					<div className='person-title'>{title}</div>
					<div className='person-subtitle'>{subtitle || phoneNumber}</div>
				</div>
				{/* {orderType === 'takeaway' && !driverMarked && (
					<Button
						size='md'
						text='Lapor Driver Mencurigakan'
						onClick={showReportDriverModal}
					/>
				)} */}
				{!isEmpty(phoneNumber) && (
					<div
						className='call-button-container'
						onClick={callPeople}>
						<FontAwesomeIcon
							icon={faPhone}
							className='call-button' />
					</div>
				)}
			</div>
			{children}
		</div>
	);
}

ContactRow.defaultProps = {
	icon: faMotorcycle,
	description: '',
	title: '-',
	subtitle: null,
	phoneNumber: '',
};

ContactRow.propTypes = {
	icon: PropTypes.object,
	description: PropTypes.string,
	title: PropTypes.string,
	subtitle: PropTypes.string,
	phoneNumber: PropTypes.string,
};

export default ContactRow;