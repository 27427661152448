import React from 'react';
import PropTypes from 'prop-types';

import {numberToRupiah} from 'utils/general';

import './index.scss';

function TotalPrice({orderData}) {

	const deliveryAmount = (orderData?.deliveryFee || 0) - (orderData?.deliveryPromoAmount || 0);

	return (
		<div className='TotalPrice'>
			<div className='price-row row no-margin-top'>
				<div className='value-title'>Subtotal</div>
				<div className='value-text'>{orderData.amount ? `${numberToRupiah(orderData.amount)}` : 'Data tidak tersedia'}</div>
			</div>
			{orderData.promoAmount > 0 && (
				<div className='price-row row'>
					<div className='value-title'>Diskon</div>
					<div className='value-text green'>-{numberToRupiah(orderData.promoAmount)}</div>
				</div>
			)}
			{orderData.type === 'delivery' && (
				<div className='price-row row'>
					<div className='value-title'>Total Biaya Antar</div>
					<div
						className={`value-text ${typeof deliveryAmount === 'number' && deliveryAmount <= 0 && 'green'}`}
					>
						{typeof deliveryAmount === 'number'
							? deliveryAmount <= 0 ? 'GRATIS' : `${numberToRupiah(deliveryAmount)}`
							: 'Data tidak tersedia'
						}
					</div>
				</div>
			)}

			<div className='seperator' />

			<div className='price-row row no-margin-top'>
				<div className='value-title bold'>Total</div>
				<div className='value-text bold'>{numberToRupiah(orderData.totalAmount)}</div>
			</div>

			<div className='price-row row no-margin-top'>
				<div className='value-title bold'>Subtotal</div>
				<div className='value-text bold'>{numberToRupiah(orderData.amount)}</div>
			</div>

		</div>
	);
}

TotalPrice.defaultProps = {
	orderData: {},
};

TotalPrice.propTypes = {
	orderData: PropTypes.object,
};


export default TotalPrice;