import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import PulseLoader from 'react-spinners/PulseLoader';
import {isEmpty} from 'lodash';
import moment from 'moment-timezone';

import Container from '@material-ui/core/Container';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronDown} from '@fortawesome/pro-solid-svg-icons';

// Global
import {
	fetchSelectedOutletData,
	subscribeOutlet,
	unsubscribeOutlet,
} from 'utils/request/outlet';
import {DAYS_NAME} from 'utils/constants';
import COLOR_PALLETES from 'assets/styles/_colors.scss';
import OutOfStockCard from 'components/OutOfStockCard';

// Local
import './index.scss';
import OutletOpenCloseDetail from './components/OutletOpenCloseDetail';
import OutletScheduleDisplay from './components/OutletScheduleDisplay';
import OutletSelectorBottomSheet from './components/OutletSelectorBottomSheet';
import {fetchOutletData, toggleOutletOpenClose} from './utils/fetch';

function Outlet() {
	const selectedOutletData = useSelector(state => state.outlet.selectedOutletData);
	const selectedOutlet = useSelector(state => state.outlet.selectedOutlet);
	const outlets = useSelector(state => state.outlet.availableOutlets);

	const [fetchingOutletData, setFetchingOutletData] = useState(true);
	const [outletSelectorOpen, setOutletSelectorOpen] = useState(false);

	const [outletOpen, setOutletOpen] = useState(false);
	const [toggleValidUntil, setToggleValidUntil] = useState();
	const [isLoadingToggleOutlet, setIsLoadingToggleOutlet] = useState(false);

	const [isOutletOperationalHour, setIsOutletOperationalHour] = useState(true);

	const onSelectOutlet = async index => {
		if (index !== selectedOutlet) {
			await unsubscribeOutlet(selectedOutlet);
			subscribeOutlet(index);
			fetchSelectedOutletData();
		}
		setOutletSelectorOpen(false);
	};

	const getSchedulesMapping = () => {
		let schedule = [];
		selectedOutletData.openHours.days.forEach(entry => {
			if (!DAYS_NAME[entry.day]) return;

			let isSame = false;
			if (schedule.length > 0) {
				const lastSchedule = schedule[schedule.length - 1];
				// 1. if both new entry and last schedule is closed, then they are categorized to be same.
				// 2. if both are not closed, their openhours and open status must be same.
				// 3. one open and one closed with the same openHours is categorized as different.
				// 4. both open but different openHours is categorized as different.
				if (
					(!lastSchedule.isOpen && !entry.isOpen) ||
					(lastSchedule.openHours === entry.openHours &&
						lastSchedule.isOpen === entry.isOpen)
				) {
					isSame = true;
				}
			}

			isSame // if it's same then append the day else create a new entry
				? schedule[schedule.length - 1].days.push(entry.day)
				: schedule.push({...entry, days: [entry.day]});
		});
		return schedule;
	};

	useEffect(() => {
		outlets.length > 0 &&
			fetchOutletData(
				setOutletOpen,
				setIsOutletOperationalHour,
				setFetchingOutletData,
				setToggleValidUntil,
			);
	}, [selectedOutlet, outlets]);

	return (
		<div className="Outlet">
			{isEmpty(outlets) || fetchingOutletData ? (
				<div className="loading-screen">
					<PulseLoader
						color={COLOR_PALLETES.sauce}
						size={16}
						margin={8} />
				</div>
			) : (
				<Container
					maxWidth="md"
					className="container">
					<div
						className="outlet-picker touchable-opacity"
						onClick={() => setOutletSelectorOpen(true)}>
						<div className="outlet-header">Outlet</div>
						<div className="row outlet-value">
							<div className="outlet-name">{outlets[selectedOutlet]?.name}</div>
							<FontAwesomeIcon
								icon={faChevronDown}
								className="outlet-picker-down-button"
							/>
						</div>
					</div>
					<OutletOpenCloseDetail
						isOpen={isOutletOperationalHour && outletOpen}
						isOutletOperationalHour={isOutletOperationalHour}
						operationalHour={
							selectedOutletData.openHours.days.find(
								entry =>
									entry.day ===
									moment.tz(selectedOutletData?.timezone || 'Asia/Jakarta').locale('en').format('ddd').toLowerCase(),
							)?.openHours
						}
						isLoadingToggleOutlet={isLoadingToggleOutlet}
						onToggleOutlet={() =>
							toggleOutletOpenClose(
								outletOpen,
								toggleValidUntil,
								setOutletOpen,
								setIsLoadingToggleOutlet,
							)
						}
						timezone={selectedOutletData?.timezone}
					/>

					<OutletScheduleDisplay
						schedules={getSchedulesMapping()}
						timezone={selectedOutletData?.timezone}
					/>

					{/* <OutOfStockCard /> */}
				</Container>
			)}

			<OutletSelectorBottomSheet
				outletList={outlets}
				selectedOutlet={selectedOutlet}
				onSelectOutlet={onSelectOutlet}
				selectorOpen={outletSelectorOpen}
				onClose={() => setOutletSelectorOpen(false)}
			/>
		</div>
	);
}

export default Outlet;
