import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheck, faClock, faTimes} from '@fortawesome/pro-solid-svg-icons';

import {TIME_LIMIT} from 'utils/constants';
import {checkIfOrderIsHistory} from 'utils/utils';
import COLOR_PALLETES from 'assets/styles/_colors.scss';

import './index.scss';

function MainDetailCard({orderData}) {
	const isHistory = checkIfOrderIsHistory(orderData);
	const elapsedMinutes = moment().subtract(orderData.createdAt, 'milliseconds').unix() / 60;
	const isOverDueNotFinished = !isHistory && elapsedMinutes > TIME_LIMIT;
	const isCancelled = orderData.status === 'cancelled';

	return (
		<div className='MainDetailCard'>
			<div className='card-body row'>
				<div
					className='icon-container row'
					style={isOverDueNotFinished || isCancelled ? {backgroundColor: COLOR_PALLETES.sauceLightest} : {}}>
					<FontAwesomeIcon
						className='icon'
						icon={isHistory
							? isCancelled
								? faTimes
								: faCheck
							: faClock}
						style={isOverDueNotFinished || isCancelled ? {color: COLOR_PALLETES.sauceLight} : {}} />
				</div>
				<div className='detail-container'>
					<div
						className='large-detail'
						style={isOverDueNotFinished || isCancelled ? {color: COLOR_PALLETES.sauceLight} : {}}>
						{isHistory
							? isCancelled
								? 'Pesanan dibatalkan'
								: 'Pesanan selesai'
							: moment(orderData.paidAt).fromNow()
						}
					</div>
					<div className='small-detail'>
						{isHistory
							? `Order ID: ${orderData.shortId}.`
							: `Sejak pesanan masuk dengan order ID: ${orderData.shortId}`
						}
					</div>
				</div>
				{/* <div className='queue-number'>{orderData.queueNo}</div> */}
			</div>
		</div>
	);
}

MainDetailCard.defaultProps = {
	orderData: {},
};

MainDetailCard.propTypes = {
	orderData: PropTypes.object,
};

export default MainDetailCard;